import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import AuthUser from "./AuthUser";

const StripeTesting = ({ setOrderBtn }) => {
  const { user } = AuthUser();
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState(user?.email);
  const [message, setMessage] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);
  useEffect(() => {
    if (!stripe) {
      // console.log("!stripe");
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      // console.log("!cleint secret");
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // console.log(paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment Successfull");
          break;
        case "processing":
          setMessage("Payment in Processing");
          break;
        case "requires_payment_method":
          setMessage("Payment was not successfull, please try again !");
          break;

        default:
          setMessage("something went wrong , please try again !");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // console.log("!stripe || !elements");
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      // confirmParams: {
      //   return_url: "https://example.com/order/123/complete",
      // },
    });

    if (result?.error) {
      // console.log(result?.error?.message);
    } else {
      // Update the state variable to indicate success
      setStatus(true);
    }
    setOrderBtn(false);

    setIsLoading(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.value.email);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };
  const Addressoptions = {
    mode: "billing",
    defaultValues: {
      name: user?.name,
      address: {
        line1: user?.address,
        city: user?.city,
        state: user?.state,
        postal_code: user?.postal_code,
        country: "US",
      },
    },
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!status ? (
        <>
          <PaymentElement options={paymentElementOptions} />
          <div className="text-center">
            <button
              type="submit"
              disabled={isloading || !stripe || !elements}
              className="bg-green-600 py-3 px-4 my-3  text-center !text-white  rounded-md"
            >
              {isloading ? "Loading..." : "Pay Now"}
            </button>
          </div>
          {message && <div id="payment-message">{message}</div>}
        </>
      ) : null}
      {status ? (
        <div id="payment-message" className="w-full text-center">
          Payment Successfull, Please wait while we are doing operations ... !
        </div>
      ) : null}
    </form>
  );
};

export default StripeTesting;
