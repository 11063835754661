import React, { useState } from "react";
import {
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { logo } from "../assets";
import { Link } from "react-router-dom";
import AuthUser from "../Component/AuthUser";
import { toast, Toaster } from "react-hot-toast";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { http, setToken } = AuthUser();
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);
  const [type, setType] = useState("password");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: pass,
    };
    //// console.log(data);
    setIsLoading(true);

    http
      .post("/api/login", data)
      .then((response) => {
        //// console.log(response.data);
        setToken(response.data.user, response.data.token);
        setIsLoading(false); // Set loading state to true
        // Do something with the response data, like show a success message
      })
      .catch((error) => {
        // console.error(error);
        setIsLoading(false);
        toast.error("Invalid login credentials. Please try again.");

        // Set loading state to true
        // Handle error
      });
    // setIsLoading(false)
  };

  return (
    <div className="flex flex-col flex-shrink justify-center items-center Treebg w-full h-[100vh]">
      <Toaster toastOptions={{ duration: 2000 }} />

      <div className="grid md:grid-cols-2 container mx-auto">
        <div>
          <div className="flex flex-col justify-center items-center w-full h-full">
            <h1 className="text-[1.5rem] md:text-[2rem] mt-[2rem] md:mt-[1rem] font-bold">
              Welcome to Dr. Meditation
            </h1>
            <img className="md:py-[2rem] md:w-40 w-20 " src={logo} alt="logo" />
            <p className="text-sm font-semibold md:px-[5rem] px-[2rem] text-center py-[2rem] md:py-[0rem] ">
              Meditation is a practice of focusing one's mind and attention in a
              particular way to achieve a state of mental calmness and
              relaxation. It is often used to reduce stress, improve
              concentration, and increase self-awareness.
            </p>
          </div>
        </div>
        <div>
          <div className="flex flex-col flex-shrink rounded-md justify-center items-center">
            <div className="flex flex-col  bg-[#92BE1F] mb-4 rounded-md md:w-[400px] md:h-[70vh] justify-center items-center px-[1rem] h-[50vh] ">
              <h3 className="text-white mb-4 text-2xl font-bold">Login</h3>

              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center "
              >
                {/* email */}
                <div>
                  <div className="relative mb-6 ">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <EnvelopeIcon className="w-5 h-5 text-gray-500" />
                    </div>
                    <input
                      required
                      type="email"
                      id="input-group-1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] pl-10 p-2.5  "
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                {/* password */}
                <div>
                  <div className="relative mb-6">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                      <LockClosedIcon className="w-5 h-5 text-gray-500" />
                    </div>
                    <input
                      required
                      id="input-group-1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] pl-10 p-2.5  "
                      placeholder="Password"
                      type={type}
                      onChange={(e) => setPass(e.target.value)}
                    />{" "}
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                      {type === "password" ? (
                        <span
                          className="icon-span"
                          onClick={() => setType("text")}
                        >
                          <EyeIcon className="h-5 w-5" />
                        </span>
                      ) : (
                        <span
                          className="icon-span"
                          onClick={() => setType("password")}
                        >
                          <EyeSlashIcon className="h-5 w-5" />
                        </span>
                      )}
                    </div>
                  </div>
                  {errorMessage && <p className="text-white">{errorMessage}</p>}
                </div>
                <label
                  htmlFor="link-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  <Link
                    className="hover:underline text-white hover:text-green-800"
                    to="/forgot"
                  >
                    Forgot Password ? {""}
                  </Link>
                  <br />
                </label>

                <div className="flex justify-center items-center ">
                  <button
                    type="submit"
                    className="bg-gradient-to-r from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-2 py-2 px-3 w-64 mt-[1.666rem] shadow-md "
                  >
                    {isLoading ? (
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      "Login"
                    )}{" "}
                  </button>
                </div>

                <label
                  htmlFor="link-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Didn't Have an Account? {""}
                  <Link
                    className="hover:underline text-white hover:text-green-800"
                    to="/signup"
                  >
                    Signup
                  </Link>
                  <br />
                </label>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
