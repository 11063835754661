import React, { useState, useEffect } from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AuthUser from "../../Component/AuthUser";
import homebanner from "../../assets/homebanner.jpg";
import LazyLoader from "../../Component/LazyLoader";

const Home = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [cat, setCat] = useState(null);
  const { token, user } = AuthUser();

  let apiToRender = `https://api.drmeditation.net/api/home/${id}`;
  const getCatName = () => {
    axios
      .get(apiToRender, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCat(response.data?.Category);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.error(error);
      });
  };
  useEffect(() => {
    getCatName();
  }, []);

  return (
    <>
      <div>
        <Header />
        {loading ? (
          <div className=" h-[15rem] md:h-[30rem] bg-[#daecc6] animate-pulse"></div>
        ) : (
          <div className=" h-[15rem] md:h-[30rem]">
            <img
              src={
                cat?.banner
                  ? `https://api.drmeditation.net/CategoryBanner/${cat?.banner}`
                  : homebanner
              }
              alt="banner"
              className="w-full h-[15rem] md:h-[30rem]"
            />
          </div>
        )}
        {/* categories for result start */}
        <div className="Treebg">
          <div className="">
            <h1 className="text-center md:pt-[3rem]  md:text-2xl font-bold text-2xl pt-[2rem]  ">
              {cat?.name} Category Content
            </h1>
            {/* <p className="text-center md:pt-[1rem] text-xl font-semibold">
              {" "}
              We recommended you this{" "}
            </p> */}
          </div>
          <div className="px-[1rem] md:container md:mx-auto ">
            <div className="grid w-full md:grid-cols-4 gap-4 mt-[2rem] sm:grid-cols-3 sm:gap-2 md:pt-[3rem]">
              <Link to={`/music/${id}?source=home`}>
                <div className="Music h-80 rounded-xl sm:h-64 md:h-[16rem] lg:h-[18rem] hover:scale-95 transition-transform duration-150">
                  <div className="flex fle-col justify-center items-center h-full">
                    <h className="text-4xl font-semibold mt-48 text-white hover:text-[#90be23] ">
                      Music
                    </h>
                  </div>
                </div>
              </Link>
              <Link to={`/blog/${id}?source=home`}>
                <div className="Blogs h-80 rounded-xl sm:h-64 md:h-[16rem] lg:h-[18rem] hover:scale-95 transition-transform duration-150">
                  <div className="flex fle-col justify-center items-center h-full">
                    <h className="text-4xl font-semibold mt-48 text-white hover:text-[#90be23]">
                      Blogs
                    </h>
                  </div>
                </div>
              </Link>
              <Link to={`/video/${id}?source=home`}>
                <div className="Video h-80 rounded-xl sm:h-64 md:h-[16rem] lg:h-[18rem] hover:scale-95 transition-transform duration-150">
                  <div className="flex fle-col justify-center items-center h-full">
                    <h className="text-4xl font-semibold mt-48 text-white hover:text-[#90be23]">
                      Video
                    </h>
                  </div>
                </div>
              </Link>
              <Link to={`/podcast/${id}?source=home`}>
                <div className="Podcasts rounded-xl  h-80 sm:h-64 md:h-[16rem] lg:h-[18rem] hover:scale-95 transition-transform duration-150">
                  <div className="flex fle-col justify-center items-center h-full">
                    <h className="text-4xl font-semibold mt-48 text-white hover:text-[#90be23]">
                      Podcast
                    </h>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* categories for result end */}
        <div className="mt-[-0.5rem]">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
