import React, { useState, useEffect } from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import axios from "axios";
import {
  PlayCircleIcon,
  XMarkIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import LazyLoader from "../../Component/LazyLoader";
import { Dialog, DialogBody, Typography } from "@material-tailwind/react";
import { Modal } from "flowbite-react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
// music player
import {
  AudioPlayerControlSprite,
  AudioPlayer,
  TrackType,
} from "react-audio-player-pro";
import reactAudioPlayerProStyle from "react-audio-player-pro/dist/style.css";
import AuthUser from "../../Component/AuthUser";
import { Surveybg, ThirdEye } from "../../assets";
import Waveform from "../Meditation/waveform";

const Podcast = () => {
  const navigate = useNavigate();
  const { token, user } = AuthUser();
  const [subuser, setSubUser] = useState(false);
  React.useEffect(() => {
    axios
      .get(
        `https://api.drmeditation.net/api/subscription/mySubscription/${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        res.data.SubscribeUser.map((u) => {
          if (Number(u.user_id) === Number(user.id)) {
            setSubUser(true);
          }
        });
      });
  }, []);
  const [loading, setLoading] = useState(false);
  const [pod, setPod] = useState([]);
  const [selectedPod, setSelectedPod] = useState(null);

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  let apiToRender;
  if (source === "home") {
    apiToRender = `https://api.drmeditation.net/api/home/${id}`;
    //// console.log("from home", apiToRender);
  } else if (source === "result") {
    apiToRender = `https://api.drmeditation.net/api/myresult/show/${user.id}`;
    //// console.log("from result", apiToRender);
  } else {
    // handle invalid or missing source parameter
    //// console.log("nothing");
  }

  // get api
  const getPodcast = () => {
    const userId = user.id;
    axios
      .get(apiToRender, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.Podcast);
        setPod(response.data.Podcast);
        setLoading(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getPodcast();
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = (music) => {
    setSelectedPod(music);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPod(null);
    setOpen(false);
  };
  return (
    <div>
      <Header />
      <div className="MainPodcastsbg h-[30rem]">
        <div className="container mx-auto">
          <div className="flex justify-center items-center h-[30rem] ">
            {/* <h1 className="text-white text-center text-[6rem] font-bold">
              Podcasts
            </h1> */}
          </div>
        </div>
      </div>
      <div className=" bg-[#dbeec5] pt-[3rem] pb-[3rem]  mx-auto">
        {" "}
        <br></br>
        <h2 className=" pb-[2rem] text-[2rem] font-semibold text-center">
          Podcast
        </h2>
      </div>
      <div className="Treeblogsbg">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center justify-center gap-2">
            {loading ? (
              pod.length ? (
                pod.map((podcast) => (
                  <>
                    {subuser ? (
                      <>
                        <div
                          onClick={() => handleOpen(podcast)}
                          className="py-[1rem]  cursor-pointer hover:scale-95 transition-transform duration-150"
                        >
                          <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50 ">
                            <a>
                              <img
                                className="rounded-t-lg md:w-[15rem] w-[13rem] h-[10rem] "
                                src={`https://api.drmeditation.net/PodcastThumbnail/${podcast.thumbnail}`}
                                // src={Surveybg}
                                alt="logo"
                              />
                            </a>
                            <div className=" p-2">
                              <a>
                                <h5 className=" text-center text-[16px] truncate font-medium tracking-tight text-gray-900 ">
                                  {podcast.name}
                                </h5>
                              </a>
                              {/* <p className="mb-1 text-center text-sm font-normal text-gray-700  line-clamp-2">
                                {podcast.description}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : podcast.subscription === "free" ? (
                      <>
                        <div
                          onClick={() => handleOpen(podcast)}
                          className=" py-[1rem] cursor-pointer hover:scale-95 transition-transform duration-150"
                        >
                          <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50 ">
                            <a>
                              <img
                                className="rounded-t-lg md:w-[15rem] w-[13rem] h-[10rem] "
                                src={`https://api.drmeditation.net/PodcastThumbnail/${podcast.thumbnail}`}
                                // src={Surveybg}
                                alt="logo"
                              />
                            </a>
                            <div className=" p-2">
                              <a>
                                <h5 className=" text-center text-[16px] truncate font-medium tracking-tight text-gray-900 ">
                                  {podcast.name}
                                </h5>
                              </a>
                              {/* <p className="mb-1 text-center text-sm font-normal text-gray-700  line-clamp-2">
                                {podcast.description}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className=" relative py-[1rem]  cursor-pointer hover:scale-95 transition-transform duration-150"
                          onClick={() => navigate("/subscriptions")}
                        >
                          <div className="absolute z-10 text-[#7ba314]  p-2 rounded-full bottom-4 right-0 ">
                            <LockClosedIcon className="h-5 w-5 " />
                          </div>
                          <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50  ">
                            <a>
                              <img
                                className="rounded-t-lg md:w-[15rem] w-[13rem] h-[10rem] "
                                src={`https://api.drmeditation.net/PodcastThumbnail/${podcast.thumbnail}`}
                                // src={Surveybg}
                                alt="logo"
                              />
                            </a>
                            <div className="p-2">
                              <a>
                                <h5 className=" text-center text-[16px] truncate font-medium tracking-tight text-gray-900 ">
                                  {podcast.name}
                                </h5>
                              </a>
                              {/* <p className="mb-1 text-center text-sm font-normal text-gray-700  line-clamp-2 ">
                                {podcast.description}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))
              ) : (
                <p className="text-center md:pt-[1rem]  text-xl font-semibold">
                  There are no Podcasts in this Category.
                </p>
              )
            ) : (
              <LazyLoader />
            )}
            {selectedPod && (
              <Modal show={open} onClose={handleClose} size="lg">
                <Modal.Body className="bg-[#d2e8b6]  rounded-lg">
                  <>
                    <div className="flex justify-end">
                      <XMarkIcon
                        color="red"
                        strokeWidth={2}
                        onClick={handleClose}
                        className="h-5 w-5 cursor-pointer	 hover:rounded-md hover:bg-blue-gray-100"
                      />
                    </div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="RoundBorder ">
                        <div className="relative right-1 bottom-1">
                          <img
                            className="rounded-full h-40 w-40 p-4 object-cover "
                            src={`https://api.drmeditation.net/PodcastThumbnail/${selectedPod.thumbnail}`}
                            // src={Surveybg}
                            alt="logo"
                          />
                        </div>
                      </div>
                      <br></br>
                      <Typography className="text-black" variant="h5">
                        {selectedPod.name}
                      </Typography>

                      <br></br>
                      <div className="px-3">
                        <div className="flex justify-center items-center md:w-58 w-[20rem] ">
                          <AudioPlayerControlSprite />
                          <AudioPlayer
                            trackList={[
                              {
                                src: `https://api.drmeditation.net/Podcast/${selectedPod.podcast}`,
                                // src: ThirdEye,
                                preload: "auto",
                                // duration: 100,
                                mediaMetadata: {
                                  title: selectedPod.name,
                                },
                              },
                            ]}
                            // string - wrapper's class name, optional, deafult: ''
                            className="my-class-name flex justify-center w-[30rem] mb-4 "
                            // callback function - called on did mount, optional, default: noop
                            // onDidMount={//// console.log}
                            // default player state, optional
                            defaultState={{
                              // boolean - is player muted, optional, default: false
                              isMuted: false,

                              // number - active song index, optional, default: 0
                              activeIndex: 0,

                              // boolean - is shuffle on, optional, default: false
                              isShuffleOn: false,

                              // boolean - is track list open, optional, default: true
                              isTrackListOpen: false,

                              // string: 'none' | 'all' | 'one' - repeating state, optional, default: 'none'
                              repeatingState: "one",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </Modal.Body>
              </Modal>
            )}
          </div>
        </div>
      </div>
      <div className="mt-[-0.5rem]">
        <Footer />
      </div>
    </div>
  );
};

export default Podcast;
