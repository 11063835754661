import React, { useState, useEffect } from "react";
import { logo } from "../assets";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import AuthUser from "../Component/AuthUser";
import axios from "axios";
import LazyLoader from "../Component/LazyLoader";

const Disclaimer = () => {
  const { token } = AuthUser();
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(true);

  const API = "https://api.drmeditation.net/api";
  // get api
  const getCategory = () => {
    axios
      .get(`${API}/disclaimer/web/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data.web_text);
        setTerms(response.data.data.web_text);
        setLoading(false);
      })
      .catch((error) => {
        // console.error(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div>
      <Header />
      <div className="Treebg ">
        <div className="flex flex-col justify-center items-center ">
          <h1 className="text-4xl text-center font-semibold mt-10 pb-4">
            Welcome to <br className="md:hidden block" /> Dr Meditation's <br />{" "}
            Disclaimer
          </h1>
          <img className="w-32 pb-4" src={logo} alt="logo" />
          <p className="px-4 md:px-16 lg:w-2/3 text-start">
            {!loading ? (
              <div dangerouslySetInnerHTML={{ __html: terms }} />
            ) : (
              <LazyLoader />
            )}
          </p>
        </div>
      </div>
      <div className="categories-footer">
        <Footer />
      </div>
    </div>
  );
};

export default Disclaimer;
