import React from "react";
import { logo } from "../assets";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
const Shop = () => {
  return (
    <div>
      <Header />
      <div className="Treebg ">
        <div className="flex flex-col justify-center items-center h-screen">
          <h1 className="text-4xl text-center font-semibold mt-10 pb-4">
            Welcome to Dr Meditation Shop Page
          </h1>
          <img className="w-32 pb-4" src={logo} alt="logo" />
          <p className="px-4 md:px-16 lg:w-2/3 text-justify">
            Meditation is a practice of focusing one's mind and attention in a
            particular way to achieve a state of mental calmness and relaxation.
            It is often used to reduce stress, improve concentration, and
            increase self-awareness. There are many different types of
            meditation, including mindfulness, loving-kindness, and
            transcendental meditation.
          </p>
        </div>
      </div>
      <div className="categories-footer">
        <Footer />
      </div>
    </div>
  );
};

export default Shop;
