import React, { useState, useEffect } from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import Waveform from "./waveform";
import {
  Chakra1,
  Chakra2,
  Chakra3,
  Chakra4,
  Chakra5,
  Chakra6,
  Chakra7,
  Root,
  Sacral,
  Heart,
  Crown,
  ThirdEye,
  Solar,
  Throat,
} from "../../assets";
import { Dialog, DialogBody } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const Meditation = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [chakra, setChakra] = useState({
    heading: null,
    name: null,
    img: null,
    audio: null,
  });

  return (
    <>
      <Header />
      <div>
        {/* audio player dialog box  */}
        {chakra && (
          <Dialog
            className="bg-[#edf5db]"
            open={open}
            handler={handleOpen}
            size="xxl"
          >
            <DialogBody className="Treebg">
              <div className="flex justify-end">
                <XMarkIcon
                  color="red"
                  strokeWidth={5}
                  onClick={handleOpen}
                  className="m-2 h-8 w-8 cursor-pointer	 hover:rounded-md hover:bg-red-300"
                />
              </div>
              <div className="flex flex-col justify-center items-center">
                <img
                  className="w-[10rem] md:w-[12rem] cursor-pointer"
                  src={chakra.img}
                  alt="chakra-img"
                />
                <h2 className="text-black font-bold md:text-5xl text-2xl ">
                  {chakra.heading}
                </h2>
                <p className="text-black font-semibold md:text-2xl text-[16px] mb-20 ">
                  {chakra.name}
                </p>

                <Waveform audio={chakra.audio} />
              </div>
            </DialogBody>
          </Dialog>
        )}
        <div className="Treebg h-full">
          <div className="conatiner mx-auto ">
            <br></br>
            <br></br>
            <p className="text-center text-xl md:text-2xl">The Seven</p>
            <h1 className="text-center text-xl md:text-3xl font-semibold ">
              CHAKRAS
            </h1>
          </div>
          <div className="  ">
            <div className="flex flex-col md:flex md:flex-row justify-center items-center gap-4 md:gap-9 mt-[1rem] md:mt-[2rem]">
              <img
                onClick={() => {
                  handleOpen();
                  setChakra({
                    heading: "SAHASRARA",
                    name: "CROWN CHAKRA",
                    img: Chakra7,
                    audio: Crown,
                  });
                }}
                className="w-[9rem] md:w-[7rem] cursor-pointer  "
                src={Chakra7}
                alt="chakra-img"
              />
              <img
                onClick={() => {
                  handleOpen();
                  setChakra({
                    heading: "AJNA",
                    name: "THIRD EYE CHAKRA",
                    img: Chakra4,
                    audio: ThirdEye,
                  });
                }}
                className="w-[9rem] md:w-[8rem] cursor-pointer"
                src={Chakra4}
                alt="chakra-img"
              />
            </div>
            <div className="flex flex-col md:flex md:flex-row justify-center items-center gap-4  md:gap-[15rem] mt-[0.5rem] md:mt-[1rem] ">
              <img
                onClick={() => {
                  handleOpen();
                  setChakra({
                    heading: "VISHUDHA",
                    name: "THROAT CHAKRA",
                    img: Chakra3,
                    audio: Throat,
                  });
                }}
                className="w-[9rem] md:w-[7rem] cursor-pointer"
                src={Chakra3}
                alt="chakra-img"
              />
              <img
                onClick={() => {
                  handleOpen();
                  setChakra({
                    heading: "ANAHATA",
                    name: "HEART CHAKRA",
                    img: Chakra2,
                    audio: Heart,
                  });
                }}
                className="w-[9rem] md:w-[7rem]  cursor-pointer"
                src={Chakra2}
                alt="chakra-img"
              />
            </div>
            <div className="flex flex-col md:flex md:flex-row justify-center items-center gap-4  md:gap-[13rem] mt-[0.5rem] md:mt-[2rem] ">
              <img
                onClick={() => {
                  handleOpen();
                  setChakra({
                    heading: "MANIPURA",
                    name: "SOLAR CHAKRA",
                    img: Chakra6,
                    audio: Solar,
                  });
                }}
                className="w-[9rem] md:w-[7rem] cursor-pointer"
                src={Chakra6}
                alt="chakra-img"
              />
              <img
                onClick={() => {
                  handleOpen();
                  setChakra({
                    heading: "SWADHISTANA",
                    name: "SACRAL CHAKRA",
                    img: Chakra5,
                    audio: Sacral,
                  });
                }}
                className="w-[9rem] md:w-[7rem] cursor-pointer"
                src={Chakra5}
                alt="chakra-img"
              />
            </div>
            <div className="flex flex-col md:flex md:flex-row justify-center items-center md:-mt-2 md:gap-[12rem]">
              <img
                onClick={() => {
                  handleOpen();
                  setChakra({
                    heading: "MULADHARA",
                    name: "ROOT CHAKRA",
                    img: Chakra1,
                    audio: Root,
                  });
                }}
                className="w-[9rem] md:w-[7rem] cursor-pointer"
                src={Chakra1}
                alt="chakra-img"
              />
            </div>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Meditation;
