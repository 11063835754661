import React, { useState, useEffect } from "react";
import OtpInput from "otp-input-react";
import "react-phone-input-2/lib/style.css";
import { auth } from "../otp/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const OTP = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [otp, setOtp] = useState("");
  const [userID, setUserID] = useState(null); // user id object from the firebase
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    const appVerifier = window.recaptchaVerifier;
    const formatPh = `+${id}`;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        //// console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        //// console.log(res);
        setLoading(false);
        axios
          .get(`https://api.drmeditation.net/api/phone/verify/${id}`)
          .then((response) => {
            // console.log(response);
            navigate("/login");
          })
          .catch((error) => {
            // console.error(error);
          });
      })
      .catch((err) => {
        //// console.log(err);
        setLoading(false);
        toast.error("Invalid OTP. Please try again.");
      });
  }
  useEffect(() => {
    onCaptchVerify();
    setTimeout(() => {
      onSignup();
    }, 3000);
  }, []);

  return (
    <div className="flex flex-col flex-shrink justify-center items-center Treebg">
      <div className="flex flex-col flex-shrink rounded-md w-[400px] min-h-screen justify-center items-center  ">
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id="recaptcha-container"></div>
        <div className="flex flex-col flex-shrink  bg-[#92BE1F] rounded-md w-[400px] h-[40vh] justify-center items-center">
          <>
            {/* otp */}
            <div>
              <div className="relative mb-6">
                <label
                  htmlFor="otp"
                  className="font-bold text-xl  text-white text-center"
                >
                  Enter your OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container mt-2"
                ></OtpInput>

                {err && <p className=" m-2 text-red-800">{err}</p>}
                <div className="flex justify-center items-center ">
                  <button
                    type="submit"
                    onClick={onOTPVerify}
                    className="bg-gradient-to-r from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-2 py-2 px-3 w-64 mt-[1.666rem] shadow-md "
                  >
                    {loading ? (
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      "Verify OTP"
                    )}
                  </button>
                </div>
                <p
                  onClick={() => window.location.reload()}
                  className=" cursor-pointer hover:text-black m-2 flex justify-center items-center text-white "
                >
                  Resend OTP
                </p>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default OTP;
