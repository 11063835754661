import React, { useState, useEffect } from "react";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import axios from "axios";
import AuthUser from "../Component/AuthUser";
import LazyLoader from "../Component/LazyLoader";
import { Card, CardHeader, Typography, Button } from "@material-tailwind/react";
import StripeCheckout from "react-stripe-checkout";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Modal, Tabs } from "flowbite-react";
import { Elements } from "@stripe/react-stripe-js";
import StripeTesting from "../Component/StripeTesting";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51NkEPmBHF0exkqPYn6C2hjiljff8Cy2XIPoOqIYOSbxGI83TPF4yMF5FINeM7osP2ZWxvmKGvVHGLnYQkGlkZlcP00BR1Gh2pR" //DR
  // "pk_test_51NiN6ZBn8IlFKsgyMTGWyFvKNekIbaKGEBT8Jil0i6GItCs9MRlSXrWHJWkwMt2Bq2kujG2O0FSyK6CPqr7U9OoE00AAVCPKBV" // DAM
);

const Subscriptions = () => {
  const { token, user } = AuthUser();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [subscriptions, setSubscriptions] = useState([]);
  const [subsid, setSubsid] = useState("");
  const [subuser, setSubUser] = useState(false);
  const [orderBtn, setOrderBtn] = useState(true);
  const [openModal, setOpenModal] = useState();
  const props = { openModal, setOpenModal };

  const navigate = useNavigate();

  // get api
  const getSubs = () => {
    axios
      .get(`https://api.drmeditation.net/api/subscription/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.SubscriptionPlan);
        setSubscriptions(response.data.SubscriptionPlan);
        setLoading(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  const handleClick = (subs) => {
    // You can perform any other actions here before setting the state if needed.
    setAmount(Number(subs.price));
    setSubsid(subs.id);
  };

  // subscription done
  const subsDone = () => {
    const data = {
      user_id: user.id,
      subscription_id: subsid,
    };
    // console.log(data);
    axios
      .post(`https://api.drmeditation.net/api/subscription/subscribe`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        toast.success("Subscription Subscribed Successfully. ");
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        setSubsid("");
      })
      .catch((error) => {
        // toast.error("Subscription Failed.Try again one more time");
        // console.error(error);
      });
  };
  useEffect(() => {
    if (!orderBtn) {
      subsDone();
    }
  }, [orderBtn]);
  // stripe configurations

  const handleToken = (tokenn) => {
    const data = {
      name: user?.name,
      token: tokenn,
      amount: amount,
    };
    // console.log(data);
    axios
      .post("https://api.drmeditation.net/api/payment/stripe", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        toast.success("Payment Successfull");
        subsDone();
      })
      .catch((err) => {
        // console.error(err);
        toast.error("Payment Not Successfull, Try Again Later !");
      });
  };
  useEffect(() => {
    axios
      .get(
        `https://api.drmeditation.net/api/subscription/mySubscription/${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        res.data.SubscribeUser.map((u) => {
          if (Number(u.user_id) === Number(user.id)) {
            setSubUser(true);
          }
        });
      });
    getSubs();
  }, []);

  const [clientSecret, setClientSecret] = useState("");
  const [stripecomp, setStripeComp] = useState(false);

  setTimeout(() => {
    setStripeComp(true);
  }, 5000);

  useEffect(() => {
    const data = {
      amount: 10,
    };
    axios
      .post("https://api.drmeditation.net/api/payment/stripe", data, {
        // .post("http://localhost:4242/create-payment-intent", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setClientSecret(res.data.clientSecret);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const loader = "auto";
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
    loader,
  };

  return (
    <div>
      <Toaster toastOptions={{ duration: 3000 }} />

      <Header />
      <h2 className="bg-[#dbeec5]  mx-auto pt-5 pb-[2rem] text-[2rem] font-semibold text-center">
        Subscriptions
      </h2>
      <div className="Treebg flex justify-center items-center">
        <div className="container mx-auto">
          <div className="flex items-center justify-center flex-wrap gap-4 p-2 mb-40">
            {subuser ? (
              <div className="text-2xl text-center col-span-3 font-semibold">
                {" "}
                You are already a subscribed Member.
              </div>
            ) : loading ? (
              subscriptions.length ? (
                subscriptions.map((subs, index) => (
                  <>
                    <Card
                      key={index}
                      color="light-green"
                      variant="gradient"
                      className="w-full mx-auto max-w-[15rem] p-4 m-0 hover:scale-95 transition-transform duration-150"
                    >
                      <CardHeader
                        floated={false}
                        shadow={false}
                        color="transparent"
                        className="m-0 mb-3 rounded-none  pb-2 text-center"
                      >
                        <Typography
                          variant="h4"
                          color="white"
                          className="font-normal text-xl uppercase"
                        >
                          {subs.time_number} {subs.time_name}
                          <div>Subscription</div>
                        </Typography>
                        <Typography
                          variant="h1"
                          color="white"
                          className="mt-6 flex justify-center gap-1 text-3xl font-normal"
                        >
                          <span className="mt-2 text-xl">$</span>
                          {subs.price}{" "}
                        </Typography>
                        <div>
                          <Button
                            size="md"
                            color="green"
                            className="text-white rounded-xl px-2 mt-10 hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                            fullWidth={true}
                            onClick={() => {
                              handleClick(subs);
                              props.setOpenModal("form-elements");
                            }}
                          >
                            Buy Subscription
                          </Button>
                          <Modal
                            show={props.openModal === "form-elements"}
                            size="5xl"
                            className=" overflow-y-auto"
                            popup
                            onClose={() => props.setOpenModal(undefined)}
                          >
                            <Modal.Header />
                            <Modal.Body>
                              <h3 className="text-xl text-center font-semibold text-black dark:text-white">
                                Subscription
                              </h3>

                              {props.openModal === "form-elements"
                                ? stripecomp && (
                                    <>
                                      <Elements
                                        options={options}
                                        stripe={stripePromise}
                                      >
                                        <div className="md:mx-[8rem]">
                                          <StripeTesting
                                            setOrderBtn={setOrderBtn}
                                          />
                                        </div>
                                      </Elements>
                                    </>
                                  )
                                : null}
                            </Modal.Body>
                          </Modal>
                        </div>
                      </CardHeader>
                    </Card>
                  </>
                ))
              ) : (
                <></>
              )
            ) : (
              <>
                <LazyLoader />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="mt-[-0.5rem]">
        <Footer />
      </div>
    </div>
  );
};

export default Subscriptions;
