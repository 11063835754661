import React from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import { Link } from "react-router-dom";
import Slider from './Slider';

const GeneralHome = () => {
  const id = 1;
  return (
    <>
      <div>
        <Header />
        <div className="ganeralbanner h-screen">
          <div className="flex justify-center items-center h-full">
            <h2 className="text-white text-[2rem] md:text-[3rem] font-semibold mb-32">
              General Name
            </h2>
          </div>
        </div>
        {/* categories for result start */}
        <div className="Treebg">
          <div className="">
            <h1 className="text-center md:pt-[3rem]  md:text-2xl font-bold text-2xl pt-[2rem]  ">
              Ganeral content
            </h1>
            {/* <p className="text-center md:pt-[1rem] text-xl font-semibold">
              {" "}
              We recommended you this{" "}
            </p> */}
          </div>
          <div className="px-[2rem] md:container md:mx-auto ">
            <div className="grid w-full md:grid-cols-4 gap-4 mt-[2rem] sm:grid-cols-3 sm:gap-2 md:pt-[3rem]">
              <Link to={`/music/${id}`}>
                <div className="Music h-80 rounded-md sm:h-64 md:h-[16rem] lg:h-[18rem]">
                  <div className="flex fle-col justify-center items-center h-full">
                    <h className="text-4xl font-semibold text-white">Music</h>
                  </div>
                </div>
              </Link>
              <Link to={`/blog/${id}`}>
                <div className="Blogs h-80 sm:h-64 md:h-[16rem] lg:h-[18rem]">
                  <div className="flex fle-col justify-center items-center h-full">
                    <h className="text-4xl font-semibold text-white">Blogs</h>
                  </div>
                </div>
              </Link>
              <Link to={`/video/${id}`}>
                <div className="Video h-80 sm:h-64 md:h-[16rem] lg:h-[18rem]">
                  <div className="flex fle-col justify-center items-center h-full">
                    <h className="text-4xl font-semibold text-white">Video</h>
                  </div>
                </div>
              </Link>
              <Link to={`/podcast/${id}`}>
                <div className="Podcasts h-80 sm:h-64 md:h-[16rem] lg:h-[18rem]">
                  <div className="flex fle-col justify-center items-center h-full">
                    <h className="text-4xl font-semibold text-white">Podcast</h>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* categories for result end */}
        <div className="Treebg" >
        <div className="container mx-auto px-[2rem] md:px-0">
            <Slider/>
        </div>
        </div>
        <div className="mt-[-0.4rem]" >
        <Footer />

        </div>
      </div>
    </>
  );
};

export default GeneralHome;
