import React, { useState, useEffect } from "react";
import { Circle, logo } from "../assets";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay } from "swiper";
import LazyLoader from "../Component/LazyLoader";
import AuthUser from "../Component/AuthUser";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";

const Categories = () => {
  const { token } = AuthUser();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [categories, setCategories] = useState([]);

  const handleClick = (id) => {
    setSelectedID(id);
    navigate(`/welcome/${id}`);
  };

  const API = "https://api.drmeditation.net/api/admin/category";
  // get api
  const getCategory = () => {
    axios
      .get(`${API}/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // //// console.log(response.data.Categories);
        setCategories(response.data.Categories);
        setLoading(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getCategory();
  }, []);

  // passing the category id
  const navigate = useNavigate();
  // const handleClick = (id) => {
  //   // //// console.log(selectedID);
  //   navigate(`/welcome/${selectedID}`);
  // };
  // const handleSkip = () => {
  //   // //// console.log(selectedID);
  //   navigate(`/home/${selectedID}`);
  // };

  return (
    <>
      <Header />
      <div className="Treebg ">
        <div className="container mx-auto px-[5rem] py-[3rem] ">
          {/* <div className="">
            <div className="flex justify-center items-center">
              <img
                className="w-[8rem] flex justify-center items-center md:w-[12rem] sm:w-[8rem] md:mt-[1rem] mt-[1rem] "
                src={Circle}
                alt="circle"
              />
            </div>
          </div> */}
          <div>
            {/* <h1 className="text-center mt-4 font-semibold text-xl ">
              Pick any one category which best defines your emotions
            </h1> */}
            <div className="text-center mt-4 font-semibold md:text-3xl text-xl">
              Select Your Category
            </div>
          </div>
          <div className="flex justify-center mt-14">
            {loading ? (
              <div className="flex items-start justify-center flex-wrap">
                {categories.map((category, index) => (
                  <div
                    className="flex flex-col justify-center items-center px-3 py-1 max-w-full h-60 cursor-pointer hover:scale-95 transition-transform duration-150"
                    key={index}
                    // onClick={handleOpen(category.id)}
                    onClick={() => handleClick(category.id)}
                  >
                    <img
                      src={`https://api.drmeditation.net/CategoryThumbnail/${category.thumbnail}`}
                      alt="categories-img"
                      className="h-[10rem] w-[15rem] rounded-xl "
                    />
                    <p className="text-center hover:text-[#90be23] mt-2 text-xl font-semibold ">
                      {category.name}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <LazyLoader />
            )}
          </div>
        </div>
      </div>
      <div className="categories-footer">
        <Footer />
      </div>
    </>
  );
};

export default Categories;
