import Message from "./Message";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../../otp/firebase.config";
import { AdviserUser, AdviserUserLady } from "../../assets";

const ChatBox = () => {
  // console.log(db)
  const messagesEndRef = useRef();
  const [messages, setMessages] = useState([]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    const q = query(
      collection(db, "messages"),
      orderBy("createdAt"),
      limit(50)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({ ...doc.data(), id: doc.id });
      });
      setMessages(messages);
    });

    return () => unsubscribe;
  }, []);

  return (
    <div className="sm:pb-44 sm:px-14   pt-10 containerWrap">
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
      <div className="" ref={messagesEndRef}></div>
    </div>
  );
};

export default ChatBox;
