import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/SignUp";
import Forgot from "./pages/Forgot";
import OTP from "./pages/OTP";
// import Home from "./pages/Home";
import NewPassword from "./pages/NewPassword";
import Categories from "./pages/Categories";
import Question from "./pages/Question";
import "./App.css";
import Result from "./pages/Result";
import WelcomePage from "./pages/WelcomePage";
import Blogs from "./pages/Blogs/Blogs";
import Podcast from "./pages/Podcast/Podcast";
import Music from "./pages/Music/Music";
import Video from "./pages/Video/Video";
import AuthUser from "./Component/AuthUser";
import Guest from "./Component/Guest";
import Home from "./pages/Home/Home";
import Adviser from "./pages/Adviser/Adviser";
import GeneralHome from "./pages/Home/GeneralHome";
import Meditation from "./pages/Meditation/Meditation";
import Chat from "./pages/Chat/Chat";
import VideoCall from "./pages/VideoCall/VideoCall";
import VoiceCall from "./pages/VoiceCall/VoiceCall";
import Advisors from "./pages/Adviser/Advisors";
import Subscriptions from "./pages/Subscriptions";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Shop from "./pages/shop";
import FAQ from "./pages/faq";
import Disclaimer from "./pages/disclaimer";
import SignUpAdvisor from "./pages/regAdvisor/SignUpAdvisor";
import AboutUs from "./pages/AboutUs";
import Loader from "./Component/Loader";
import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";

function App() {
  const [showPreloader, setShowPreloader] = useState(true);

  const location = useLocation();

  useEffect(() => {
    setShowPreloader(true);

    const preloaderTimeout = setTimeout(() => {
      setShowPreloader(false);
    }, 1000);

    return () => clearTimeout(preloaderTimeout);
  }, [location]);

  const { getToken } = AuthUser();
  if (!getToken()) {
    return <Guest />;
  }
  return (
    <div className="App ">
      {showPreloader ? (
        <>
          <Header />
          <Loader />
          <Footer />
        </>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to={"/"} />} />
          <Route path="/" element={<Navigate to={"/categories"} />}></Route>
          {/* <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/forgot" element={<Forgot />}></Route>
        <Route path="/otp/:id" element={<OTP />}></Route> */}
          <Route path="/newpassword" element={<NewPassword />}></Route>
          <Route path="/categories" element={<Categories />}></Route>
          <Route path="/question/:id" element={<Question />}></Route>
          <Route path="/result" element={<Result />}></Route>
          <Route path="/welcome/:id" element={<WelcomePage />}></Route>
          <Route path="/blog/:id" element={<Blogs />}></Route>
          <Route path="/podcast/:id" element={<Podcast />}></Route>
          <Route path="/music/:id" element={<Music />}></Route>
          <Route path="/video/:id" element={<Video />}></Route>
          <Route path="/home/:id" element={<Home />}></Route>
          <Route path="/advisor/:id" element={<Adviser />}></Route>
          <Route path="/advisors" element={<Advisors />}></Route>
          <Route path="/general" element={<GeneralHome />}></Route>
          <Route path="/meditation" element={<Meditation />}></Route>
          <Route path="/chat" element={<Chat />}></Route>
          <Route path="/voice" element={<VoiceCall />}></Route>
          <Route path="/video" element={<VideoCall />}></Route>
          <Route path="/subscriptions" element={<Subscriptions />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/shop" element={<Shop />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/disclaimer" element={<Disclaimer />}></Route>
          <Route path="/about" element={<AboutUs />}></Route>
          <Route path="/regadvisor" element={<SignUpAdvisor />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
