// import { UserAuth } from "../context/AuthContext";
import AuthUser from "../../Component/AuthUser";
import { AdviserUser } from "../../assets";

const Message = ({ message }) => {
  const { user } = AuthUser();
  // //// console.log(user);
  // const img = user.image
  //   ? `https://drmeditation.net/${user.image}`
  //   : AdviserUser;

  // //// console.log(message);

  return (
    <div>
      <div
        className={`chat  
           ${message.uid === user.id ? "chat-end" : "chat-start"}
        `}
      >
        <div className="chat-image avatar">
          <div className="w-10 rounded-full">
            <img src={AdviserUser} alt="user image" />
          </div>
        </div>
        <div className="chat-header">
          {message.name}
          <time className="text-xs opacity-50 pl-1">{message.time}</time>
        </div>
        <div
          className={`chat-bubble  
           ${message.uid === user.id ? "" : "bg-success"}
        `}
        >
          {message.text}
        </div>
      </div>
    </div>
  );
};

export default Message;
