import React, { useState } from "react";
import {
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
  EyeIcon,
  EyeSlashIcon,
  MapPinIcon,
  BookOpenIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  ClipboardDocumentIcon,
  PhoneArrowDownLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import Icon from "react-icons-kit";
import { arrows_exclamation } from "react-icons-kit/linea/arrows_exclamation";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";
import { Link } from "react-router-dom";
import { logo } from "../../assets";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../Component/AuthUser";
import { toast, Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { Dialog, DialogBody } from "@material-tailwind/react";
import Footer from "../../Component/Footer/Footer";
import Header from "../../Component/Header/Header";

const SignUpAdvisor = () => {
  // password validation
  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  const [pgNo, setPgNo] = useState(1);
  const { http } = AuthUser();
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("password");
  const [image, setImage] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [education, SetEducation] = useState("");
  const [biodata, SetBiodata] = useState("");
  const [certificates, SetCertificates] = useState("");
  const [degrees, SetDegrees] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const handleClose = () => {
    setOpen(!open);
    window.location.href = "https://drmeditation.net/";
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !specialValidated ||
      !lengthValidated
    ) {
      // alert(
      //   "Please ensure that your Password meets all the validation criteria."
      // );
      toast.error(
        "Please ensure that your Password meets all the validation criteria."
      );
      setIsLoading(false);

      return;
    }
    // const data = new FormData()
    // data.append("name", username)
    // data.append("email",email)
    // data.append("password",password)
    // data.append("phone",phone)
    // data.append("address",address)
    // data.append("education",education)
    // data.append("biodata",biodata)
    // data.append("certificates",certificates)
    // data.append("degrees",degrees)
    const data = {
      image: image,
      name: username,
      email: email,
      password: password,
      phone: phone,
      address: address,
      education: education,
      biodata: biodata,
      certificates: certificates,
      degrees: degrees,
    };
    // console.log(data);
    http
      .post("/api/advisor/register", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        setIsLoading(false);
        handleOpen();
        // navigate(`/otp/${phone}`);
      })
      .catch((error) => {
        console.error(error);
        // setErr(error.response.data.errors[0]);
        toast.error(`${error.response.data.errors[0]}`);
        setIsLoading(false);
        // Handle error
      });
  };

  const handleChange = (value) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{6,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
    setPassword(value);
  };

  const handleEduDocs = (e) => {
    const files = Array.from(e.target.files);
    SetEducation(files);
  };
  const handleCertDocs = (e) => {
    const files = Array.from(e.target.files);
    SetCertificates(files);
  };
  const handleDegDocs = (e) => {
    const files = Array.from(e.target.files);
    SetDegrees(files);
  };

  return (
    <>
      <div className="flex flex-col flex-shrink justify-center  items-center overflow-y-auto Treebg w-full h-[100vh]">
        <Toaster toastOptions={{ duration: 2000 }} />
        {/* success register modal  */}
        <Dialog
          open={open}
          handler={handleOpen}
          size="lg"
          className="h-screen overflow-auto"
        >
          <DialogBody className="bg-[#daeec8] rounded-lg ">
            <div className="flex justify-end">
              <XMarkIcon
                color="red"
                strokeWidth={2}
                onClick={handleClose}
                className="m-2 h-5 w-5 cursor-pointer	 hover:rounded-md hover:bg-blue-gray-100"
              />
            </div>
            <div className="mt-10 h-full">
              <div className="flex flex-col justify-center items-center h-full w-full">
                <h1 className="text-2xl font-semibold pb-[2rem]">
                  Thank you for Registration at Dr. Meditation
                </h1>
                <img className="w-[5rem] pb-[2rem] " src={logo} alt="logo" />
                <p className="md:px-[3rem] w-full pb-5 ">
                  Dear {username}, <br /> Welcome to the Dr. Meditation
                  platform! We are thrilled to have you join our team of
                  dedicated professionals and experts who are passionate about
                  promoting wellness and guiding individuals on their meditation
                  journey. Your expertise and experience in the field of
                  wellness and meditation are invaluable additions to our
                  platform.
                  <br /> We believe that your unique insights and guidance will
                  immensely benefit our users, helping them achieve mental and
                  emotional well-being. At Dr. Meditation, we strive to create a
                  nurturing and supportive environment for our community
                  members. We believe that meditation and wellness practices
                  have the power to transform lives, and we are committed to
                  making these practices accessible to everyone. Your role as an
                  advisor is crucial in fulfilling this mission.
                  <br /> As an advisor, you will have the opportunity to connect
                  with individuals from diverse backgrounds who are seeking
                  guidance and support in their meditation practice. Your
                  compassionate and knowledgeable approach will empower them to
                  cultivate mindfulness, reduce stress, and enhance their
                  overall well-being. We encourage you to bring your unique
                  perspectives and ideas to the platform. We value innovation
                  and are always open to exploring new approaches to wellness
                  and meditation. Your contributions will help shape our
                  platform and ensure that we continue to provide the most
                  effective and impactful resources to our users.
                  <br /> Please take the time to familiarize yourself with our
                  platform, its features, and guidelines. We have a dedicated
                  team that is ready to assist you with any questions or
                  concerns you may have along the way.
                  <br /> Once again, Welcome to the Dr. Meditation platform! We
                  are excited to embark on this journey together and make a
                  positive impact on the lives of countless individuals through
                  the power of meditation and wellness practices. Wishing you a
                  fulfilling and rewarding experience!
                  <br /> Warm regards,
                  <br /> Team Dr. Meditation
                </p>
              </div>
            </div>
          </DialogBody>
        </Dialog>
        <div className="grid md:grid-cols-2">
          <div>
            <div className="flex flex-col justify-center items-center w-full h-full md:mt-[-2rem] mt-[1.5rem]">
              <img src={logo} alt="logo" />
              <p className="text-[1.8rem] font-bold">
                {" "}
                Start your journey with us
              </p>
              <p className="text-[1.5rem] font-bold">To Becoming an Advisor</p>
            </div>
          </div>
          <div>
            <div className="flex flex-col flex-shrink  rounded-lg justify-center  md:h-[90vh] md:mt-0 mt-[3rem] items-center">
              <div className="flex flex-col bg-[#92BE1F] rounded-2xl w-[380px] justify-center items-center mt-[0.444rem] md:ml-[6rem]">
                <h3 className="text-white text-2xl md:mb-4 md:text-2xl font-bold mt-[2rem]">
                  Become an Advisor
                </h3>
                {/* form  */}

                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-center "
                >
                  <div>
                    {pgNo === 1 ? (
                      <>
                        {/* profile image */}
                        <div className="flex space-x-6 flex-shrink-0">
                          <img
                            className="h-16 w-16 rounded-full"
                            src={
                              image
                                ? URL.createObjectURL(image)
                                : "https://png.pngtree.com/png-vector/20191110/ourmid/pngtree-avatar-icon-profile-icon-member-login-vector-isolated-png-image_1978396.jpg"
                            }
                            alt=" profile photo"
                          />
                          <input
                            type="file"
                            id="files"
                            className="hidden"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                            }}
                          />
                          <label
                            className="bg-gradient-to-r cursor-pointer text-[16px] from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-3 py-2 px-10  shadow-md"
                            htmlFor="files"
                          >
                            Upload Profile
                          </label>
                        </div>
                        {/* fullname */}
                        <div>
                          <div className="relative mb-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <UserIcon className="w-5 h-5 text-gray-500" />
                            </div>
                            <input
                              value={username}
                              required
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] mt-[2rem] pl-10 p-2.5  "
                              placeholder="Full Name"
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* email  */}
                        <div>
                          <div className="relative mb-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <EnvelopeIcon className="w-5 h-5 text-gray-500" />
                            </div>
                            <input
                              required
                              value={email}
                              type="email"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] pl-10 p-2.5  "
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* address  */}
                        <div>
                          <div className="relative mb-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <MapPinIcon className="w-5 h-5 text-gray-500" />
                            </div>
                            <input
                              value={address}
                              required
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem]  pl-10 p-2.5  "
                              placeholder="Address"
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* phone number  */}
                        <div>
                          <div className="relative  mb-2">
                            <PhoneInput
                              country={"us"}
                              autocomplete="username"
                              value={phone}
                              onChange={setPhone}
                            />
                          </div>
                        </div>
                        {/* password  */}
                        <div>
                          <div className="relative mb-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <LockClosedIcon className="w-5 h-5 text-gray-500" />
                            </div>
                            <input
                              required
                              type={type}
                              value={password}
                              className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] pl-10 p-2.5  "
                              placeholder="Password"
                              onChange={(e) => handleChange(e.target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                              {type === "password" ? (
                                <span
                                  className="icon-span"
                                  onClick={() => setType("text")}
                                >
                                  <EyeIcon className="h-5 w-5" />
                                </span>
                              ) : (
                                <span
                                  className="icon-span"
                                  onClick={() => setType("password")}
                                >
                                  <EyeSlashIcon className="h-5 w-5" />
                                </span>
                              )}
                            </div>
                          </div>
                          {password ? (
                            <main className="tracker-box mb-2">
                              <div
                                className={
                                  lowerValidated ? "validated" : "not-validated"
                                }
                              >
                                {lowerValidated ? (
                                  <span className="list-icon green">
                                    <Icon icon={arrows_circle_check} />
                                  </span>
                                ) : (
                                  <span className="list-icon">
                                    <Icon icon={arrows_exclamation} />
                                  </span>
                                )}
                                At least one lowercase letter
                              </div>
                              <div
                                className={
                                  upperValidated ? "validated" : "not-validated"
                                }
                              >
                                {upperValidated ? (
                                  <span className="list-icon green">
                                    <Icon icon={arrows_circle_check} />
                                  </span>
                                ) : (
                                  <span className="list-icon">
                                    <Icon icon={arrows_exclamation} />
                                  </span>
                                )}
                                At least one uppercase letter
                              </div>
                              <div
                                className={
                                  numberValidated
                                    ? "validated"
                                    : "not-validated"
                                }
                              >
                                {numberValidated ? (
                                  <span className="list-icon green">
                                    <Icon icon={arrows_circle_check} />
                                  </span>
                                ) : (
                                  <span className="list-icon">
                                    <Icon icon={arrows_exclamation} />
                                  </span>
                                )}
                                At least one number
                              </div>
                              <div
                                className={
                                  specialValidated
                                    ? "validated"
                                    : "not-validated"
                                }
                              >
                                {specialValidated ? (
                                  <span className="list-icon green">
                                    <Icon icon={arrows_circle_check} />
                                  </span>
                                ) : (
                                  <span className="list-icon">
                                    <Icon icon={arrows_exclamation} />
                                  </span>
                                )}
                                At least one special character
                              </div>
                              <div
                                className={
                                  lengthValidated
                                    ? "validated"
                                    : "not-validated"
                                }
                              >
                                {lengthValidated ? (
                                  <span className="list-icon green">
                                    <Icon icon={arrows_circle_check} />
                                  </span>
                                ) : (
                                  <span className="list-icon">
                                    <Icon icon={arrows_exclamation} />
                                  </span>
                                )}
                                At least 6 characters
                              </div>
                            </main>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : pgNo === 2 ? (
                      <>
                        {/* educational  */}
                        <div className="flex flex-shrink-0">
                          <input
                            type="file"
                            id="edu"
                            className="hidden"
                            multiple
                            onChange={handleEduDocs}
                          />
                          <label
                            className="bg-gradient-to-r cursor-pointer text-[15px] from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-3 py-3 px-10  shadow-md"
                            for="edu"
                          >
                            Upolad Educational Documents
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* biodata */}
                        <div className="relative ml-2 mb-2">
                          <div className="absolute inset-y-0 left-0 top-3 flex items-start self-start justify-start pl-3 pointer-events-none">
                            <CalendarDaysIcon className="w-5 h-5 text-gray-500" />
                          </div>
                          <textarea
                            rows={2}
                            value={biodata}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block  pl-10 p-2.5  "
                            placeholder="Tell us about yourself."
                            onChange={(e) => SetBiodata(e.target.value)}
                          />
                        </div>

                        {/* certificates  */}
                        <div className="flex flex-shrink-0">
                          <input
                            type="file"
                            id="cert"
                            className="hidden"
                            multiple
                            onChange={handleCertDocs}
                          />
                          <label
                            className="bg-gradient-to-r cursor-pointer text-[15px] from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-3 py-3 px-10  shadow-md"
                            for="cert"
                          >
                            Upolad Certificate(s)
                          </label>
                        </div>
                        {/* degrees  */}
                        <div className="flex flex-shrink-0">
                          <input
                            type="file"
                            id="deg"
                            className="hidden"
                            multiple
                            onChange={handleDegDocs}
                          />
                          <label
                            className="bg-gradient-to-r cursor-pointer text-[15px] from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-3 py-3 px-10  shadow-md"
                            for="deg"
                          >
                            Upolad Degree(s)
                          </label>
                        </div>
                        {/* privacy  */}
                        <div className="flex items-center">
                          <input
                            required
                            id="link-checkbox"
                            type="checkbox"
                            defaultValue=""
                            className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="link-checkbox"
                            className="ml-2 mt-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            I agree with the{" "}
                            <Link
                              className="hover:underline  text-green-800 hover:text-white"
                              to="/"
                            >
                              terms & conditions
                            </Link>
                            <br></br>
                            {" & "}
                            <Link
                              className="hover:underline  text-green-800 hover:text-white"
                              to="/"
                            >
                              Privacy Policy
                            </Link>
                          </label>
                        </div>
                      </>
                    )}
                  </div>

                  {/* buttons */}
                  <div className="flex justify-center items-center ">
                    {pgNo > 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          setPgNo(pgNo - 1);
                        }}
                        className="bg-gradient-to-r from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-2 py-2 px-3  w-32 shadow-md "
                      >
                        Back
                      </button>
                    )}
                    {pgNo === 3 ? (
                      <button
                        type="submit"
                        className="bg-gradient-to-r from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-2 py-2 px-3  w-32 shadow-md "
                      >
                        {isLoading ? (
                          <svg
                            aria-hidden="true"
                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        ) : (
                          "Register"
                        )}{" "}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          setPgNo(pgNo + 1);
                        }}
                        className="bg-gradient-to-r from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-2 py-2 px-3  w-32 shadow-md "
                      >
                        Next
                      </button>
                    )}
                  </div>
                </form>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpAdvisor;
