import React, { useState, useEffect } from "react";
import { logo } from "../../assets";
import {
  UserCircleIcon,
  XMarkIcon,
  UserIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/solid";
import "../../App.css";
import { Link } from "react-router-dom";
import AuthUser from "../AuthUser";
import { Dropdown, Navbar } from "flowbite-react";
import { Dialog, DialogBody, Typography } from "@material-tailwind/react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const Header = () => {
  const { http, user, token, logout } = AuthUser();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [img, setImg] = useState(user?.image);
  const [categories, setCategories] = useState([]);

  // const logoutUser = () => {
  //   if (token !== undefined) {
  //     axios
  //       .get(`https://api.drmeditation.net/api/logout/${user?.id}`)
  //       .then((res) => {
  //         //// console.log(res.data);
  //         logout();
  //       })
  //       .catch((error) => {
  //         // console.error(error);
  //       });
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      id: user?.id,
      name: name,
      email: email,
      image: img,
    };
    //// console.log(data);
    axios
      .post("https://api.drmeditation.net/api/profile", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        //// console.log(res.data);
        setIsLoading(false);
        toast.success("User Updated Successfully");
      })
      .catch((error) => {
        // console.error(error);
        setIsLoading(false);
        toast.error("Try Again !");
        // Handle error
      });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const API = "https://api.drmeditation.net/api/admin/category";
  // get api
  const getCategory = () => {
    axios
      .get(`${API}/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // //// console.log(response.data.Categories);
        setCategories(response.data.Categories);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div>
      <div className="sticky w-full  z-20 top-0 left-0">
        {/* navbar  */}
        <Navbar className="!bg-[#dbeec5] " rounded={true}>
          <Navbar.Brand href="/">
            <img src={logo} className="h-[5rem] " alt="Flowbite Logo" />
            <span className="text-[16px]">Dr. Meditation</span>
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse>
            <Link
              className="hover:!text-[#90be23] py-2 border-b-2 text-[16px] md:border-none "
              to="/"
            >
              Home
            </Link>

            <div className="hover:!text-[#90be23]  py-2 border-b-2 text-[16px] md:border-none ">
              <Dropdown
                arrowIcon={false}
                inline={true}
                label="Categories"
                trigger="hover"
                className="hover:!text-[#90be23]  border-b-2 text-[16px] md:border-none "
              >
                {categories?.map((category, index) => (
                  <div key={index}>
                    <Link
                      className="hover:!text-[#90be23]  py-2 border-b-2 text-[16px] md:border-none "
                      to={`/home/${category?.id}`}
                    >
                      <Dropdown.Item className="hover:!text-[#90be23] !bg-white">
                        <span className="block text-sm hover:!text-[#90be23] ">
                          {category?.name}
                        </span>
                      </Dropdown.Item>
                    </Link>
                    <Dropdown.Divider />
                  </div>
                ))}
              </Dropdown>
            </div>
            <Link
              className="hover:!text-[#90be23] py-2 border-b-2 text-[16px] md:border-none "
              to="/meditation"
            >
              Meditation
            </Link>
            <Link
              className="hover:!text-[#90be23] py-2 border-b-2 text-[16px] md:border-none "
              to="/advisors"
            >
              Advisors
            </Link>
            <Link
              className="hover:!text-[#90be23] py-2 border-b-2 text-[16px] md:border-none "
              to="/about"
            >
              About Us
            </Link>
            <div className="py-1 border-b-2  md:border-none  ">
              <Dropdown
                arrowIcon={false}
                inline={true}
                label={
                  <UserCircleIcon className="w-7  text-gray-500 cursor-pointer" />
                }
              >
                <Dropdown.Header className="">
                  <span className="block text-sm">{user?.name}</span>
                  <span className="block truncate text-sm font-medium">
                    {user?.email}
                  </span>
                </Dropdown.Header>
                <Dropdown.Item className="h-5 ">
                  {" "}
                  <div
                    className="block  py-2 text-sm text-gray-700 "
                    onClick={() => handleOpen()}
                  >
                    Profile
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="h-5 ">
                  <Link
                    to={"/subscriptions"}
                    className="block  py-2 text-sm text-gray-700 "
                  >
                    Subscriptions
                  </Link>
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item onClick={logout} className="h-5 ">
                  {" "}
                  <div
                    onClick={logout}
                    className="block  py-2 text-sm text-gray-700 "
                  >
                    Logout
                  </div>
                </Dropdown.Item>
              </Dropdown>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
      {/* navbar end */}

      {/* profile dialog box  */}
      <Dialog
        className="overflow-y-auto bg-[#dbeec5]"
        size="xl"
        open={open}
        handler={handleOpen}
      >
        <DialogBody divider className="p-0 px-[1rem] pt-[1rem]">
          <>
            <Toaster toastOptions={{ duration: 2000 }} />
            <div className="flex justify-end">
              <XMarkIcon
                color="red"
                strokeWidth={2}
                onClick={handleOpen}
                className="m-1 h-5 w-5 cursor-pointer	 hover:rounded-md hover:bg-blue-gray-100"
              />
            </div>
            <div className="flex justify-center items-center flex-col">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center "
              >
                {/* user image  */}
                <div className="flex flex-wrap justify-center gap-6">
                  {img ? (
                    <img
                      className="h-16 w-16 object-cover rounded-full"
                      src={
                        img instanceof File
                          ? URL?.createObjectURL(img)
                          : `https://api.drmeditation.net/Profile/${img}`
                      }
                      alt="Current profile photo"
                    />
                  ) : (
                    <img
                      className="h-16 w-16 object-cover rounded-full"
                      src={`https://png.pngtree.com/png-vector/20191110/ourmid/pngtree-avatar-icon-profile-icon-member-login-vector-isolated-png-image_1978396.jpg`}
                      alt="Current profile photo"
                    />
                  )}
                  <input
                    type="file"
                    id="files"
                    className="hidden"
                    onChange={(e) => {
                      setImg(e.target.files[0]);
                    }}
                  />
                  <label
                    className="bg-gradient-to-r from-[#90be23] to-[#6ec871] cursor-pointer rounded-full text-white font-normal m-3 py-2 px-10  shadow-md"
                    for="files"
                  >
                    Upolad
                  </label>
                </div>
                {/* full name */}
                <div>
                  <div className="relative  ">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 ">
                      <UserIcon className="w-5 h-5 text-gray-500" />
                    </div>
                    <input
                      value={name}
                      required
                      type="text"
                      id="input-group-1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold focus:ring-green-500 focus:border-green-500 block sm:w-full w-[9rem] md:w-[15rem] w-[13rem] mt-6  pl-10 p-2"
                      placeholder="Full Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                {/* email */}
                {/* <div>
                  <div className="relative ">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <EnvelopeIcon className="w-5 h-5 text-gray-500" />
                    </div>
                    <input
                      required
                      value={email}
                      type="email"
                      id="input-group-1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold focus:ring-green-500 focus:border-green-500 block sm:w-full w-[10rem] md:w-[15rem] w-[13rem] mt-6  pl-10 p-2"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* save button  */}
                <div className="flex justify-center items-center ">
                  <button
                    type="submit"
                    className="bg-gradient-to-r from-[#90be23] to-[#6ec871] rounded-full text-white font-normal m-2 py-2 px-3 w-32 shadow-md "
                  >
                    {isLoading ? (
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      "Save"
                    )}{" "}
                  </button>
                </div>
                {/* evalutaion */}
                <div className=" sm:-mr-1 ">
                  <div className="relative  mb-6 text-white">
                    <Typography className="">
                      <button
                        type="button"
                        className="bg-gradient-to-r from-[#90be23] to-[#6ec871]
 md:text-md lg:text-[16px]  rounded-lg text-white font-normal m-1 py-2 px-3 w-full md:w-64 mt-4 md:mt-6 text-base  shadow-md"
                      >
                        Surveys Conducted : 0
                      </button>
                      <button
                        type="button"
                        className="bg-gradient-to-r from-[#90be23] to-[#6ec871] md:text-md lg:text-[16px]  rounded-lg text-white font-normal m-1 py-2 px-3 w-full md:w-64 mt-4 md:mt-6 text-base  shadow-md"
                      >
                        Latest Evaluation : Depression
                      </button>
                    </Typography>
                    <Typography>
                      <button
                        type="button"
                        className="bg-gradient-to-r from-[#90be23] to-[#6ec871] rounded-full m-1 text-white font-normal  py-2 px-3 w-full md:w-64 mt-4 md:mt-6 text-base md:text-sm shadow-md"
                      >
                        <Link to="/categories" className="text-white">
                          Take a New Survey?
                        </Link>
                      </button>
                      <button
                        type="button"
                        className="bg-gradient-to-r from-[#90be23] to-[#6ec871] rounded-full m-1 text-white font-normal  py-2 px-3 w-full md:w-64 mt-4 md:mt-6 text-base md:text-sm shadow-md"
                      >
                        <Link to="/result" className="text-white">
                          See Your Evaluation!
                        </Link>
                      </button>
                    </Typography>
                  </div>
                </div>
              </form>
            </div>
            <></>
          </>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default Header;
