import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function AuthUser() {
  const navigate = useNavigate();

  const getToken = () => {
    // const tokenString = sessionStorage.getItem("token");
    const tokenString = Cookies.get("token");
    if (tokenString) {
      const userToken = tokenString;
      return userToken;
    }
    return null;
  };

  const getUser = () => {
    // const userString = sessionStorage.getItem("user");
    const userCookie = Cookies.get("user");
    const user_detail = userCookie ? JSON.parse(userCookie) : null;
    return user_detail;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (user, token) => {
    // Assuming you have a token stored in a variable called 'token'
    // sessionStorage.setItem("token", JSON.stringify(token));
    // sessionStorage.setItem("user", JSON.stringify(user));
    Cookies.set("token", token, { expires: 1 });
    Cookies.set("user", JSON.stringify(user), { expires: 1 });
    setToken(token);
    setUser(user);
    navigate("/categories");
  };

  const logout = () => {
    // sessionStorage.clear();
    Cookies.remove("token");
    Cookies.remove("user");
    handlelogout();
    navigate("/login");
  };

  const handlelogout = () => {
    axios
      .get(`https://api.drmeditation.net/api/logout/${user.id}`)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => console.error(err));
  };

  const http = axios.create({
    baseURL: "https://api.drmeditation.net",
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  });

  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    http,
    logout,
  };
}
