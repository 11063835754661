import React, { useState, useEffect } from "react";
import "../../App.css";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import { AboutUnder, AdviserUser } from "../../assets";

import {
  ChatBubbleLeftEllipsisIcon,
  LockClosedIcon,
  PhoneIcon,
  StarIcon,
  XMarkIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthUser from "../../Component/AuthUser";
import StripeCheckout from "react-stripe-checkout";
import { toast, Toaster } from "react-hot-toast";
import LazyLoader from "../../Component/LazyLoader";
import { Modal, Tabs } from "flowbite-react";
import { Elements } from "@stripe/react-stripe-js";
import StripeTesting from "../../Component/StripeTesting";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51NkEPmBHF0exkqPYn6C2hjiljff8Cy2XIPoOqIYOSbxGI83TPF4yMF5FINeM7osP2ZWxvmKGvVHGLnYQkGlkZlcP00BR1Gh2pR" // DR
);

const Adviser = () => {
  const navigate = useNavigate();
  const { token, user } = AuthUser();
  const [subuser, setSubUser] = useState(false);
  const [vcv, setVCV] = useState(true);
  const [advisor, setAdvisor] = useState();
  const [servicetype, setServiceType] = useState();
  const [serviceprice, setServicePrice] = useState();
  const [slots, setSlots] = useState();
  const [uniqueDays, setUniqueDays] = useState([]);
  const [date, setDate] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [orderBtn, setOrderBtn] = useState(true);
  const [openModal, setOpenModal] = useState();
  const props = { openModal, setOpenModal };

  const [services, setServices] = useState([]);

  const getAdvisor = () => {
    axios
      .get(`https://api.drmeditation.net/api/advisors/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        const filteredData = response.data?.all_advisor?.filter((advisor) => {
          return Number(advisor.id) === Number(id);
        });
        // console.log(filteredData[0]);
        setAdvisor(filteredData);
        setSlots(filteredData[0]?.slots);
        const days = [
          ...new Set(filteredData[0]?.slots.map((item) => item.day)),
        ];
        setLoading(false);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  useEffect(() => {
    axios
      .get(
        `https://api.drmeditation.net/api/subscription/mySubscription/${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        res.data.SubscribeUser.map((u) => {
          if (Number(u.user_id) === Number(user.id)) {
            setSubUser(true);
          }
        });
      });
  }, []);

  const getServices = () => {
    axios
      .get(`https://api.drmeditation.net/api/service/show/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setServices(response.data.data);
        // setLoading(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  const getVCV = () => {
    axios
      .get(`https://api.drmeditation.net/api/advisors/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setVCV(response.data.VCV);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getServices();
    getVCV();
    getAdvisor();
  }, []);
  useEffect(() => {
    // Filter slots based on selected day
    if (selectedDay !== "") {
      const filteredSlots = slots
        ?.map((slot) => {
          const isBooked = slot.booking.some((booking) => {
            const bookingDate = new Date(booking.date);
            const selectedDate = new Date(date);
            return (
              bookingDate.toDateString() === selectedDate.toDateString() &&
              booking.status === "pending"
            );
          });
          return {
            ...slot,
            isBooked: isBooked,
          };
        })
        .filter((slot) => slot.day.name === selectedDay);
      console.log(filteredSlots);
      setUniqueDays(filteredSlots);
    }
  }, [selectedDay, date, slots]);
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };

  // booking done
  const bookingDone = () => {
    const data = {
      user_id: user.id,
      advisor_id: advisor[0]?.id,
      service_type: servicetype,
      service_price: serviceprice,
      slot_id: selectedButton,
      date: date,
    };
    console.log(data);
    axios
      .post(`https://api.drmeditation.net/api/booking/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Booking Successfully. ");
        props.setOpenModal(undefined);
      })
      .catch((error) => {
        // toast.error("Subscription Failed.Try again one more time");
        console.error(error);
      });
  };

  useEffect(() => {
    if (!orderBtn) {
      bookingDone();
    }
  }, [orderBtn]);
  // stripe funcitonality

  const [clientSecret, setClientSecret] = useState("");
  const [stripecomp, setStripeComp] = useState(false);

  setTimeout(() => {
    setStripeComp(true);
  }, 5000);

  useEffect(() => {
    const data = {
      amount: 10,
    };
    axios
      .post("https://api.drmeditation.net/api/payment/stripe", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setClientSecret(res.data.clientSecret);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const loader = "auto";
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
    loader,
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 2000 }} />

      <Header />
      {!loading ? (
        <>
          <div className="Treebg ">
            {/* <div className="adviserbg h-full"> */}
            <div>
              <div className="container mx-auto max-w-screen-lg px-4 py-5">
                <div className="w-full mx-auto ">
                  <div className="flex flex-col xl:flex-row lg:flex-row sm:flex-col md:flex-col items-center justify-center h-screen rounded-lg">
                    <div className=" md:p-10 flex  justify-center items-center relative">
                      <div className="bg-white w-[15rem] h-[15rem] rounded-lg relative">
                        <img
                          className=" w-[15rem] h-[15rem] rounded-lg"
                          src={
                            advisor[0]?.image
                              ? `https://api.drmeditation.net/Profile/${advisor[0]?.image}`
                              : AdviserUser
                          }
                          alt="adviser-img"
                        />
                      </div>
                    </div>
                    <div className="md:p-5 flex flex-col justify-center items-start">
                      <div className="flex items-center">
                        <p className="md:mr-2  text-xl font-bold"></p>
                      </div>

                      <div className="flex-col bg-[#91bd1f] w-full  p-2 md:my-0 my-3 rounded-lg hover:shadow-lg ">
                        <h2 className="font-medium md:pl-2 text-center   capitalize">
                          {advisor[0]?.name}
                        </h2>
                      </div>
                      <div className="flex flex-wrap justify-center gap-5 mt-5">
                        <>
                          <div className="flex flex-wrap justify-center gap-5 ">
                            <div className="bg-[#91bd1f] flex flex-wrap items-center md:justify-center md:gap-x-10 gap-2 py-2  md:py-3 px-5 md:px-5 rounded-xl">
                              <>
                                <div
                                  onClick={() => {
                                    props.setOpenModal("form-elements");
                                    setServiceType("Voice Call");
                                    setServicePrice(vcv?.voice);
                                  }}
                                  className="flex-col bg-gray-100 p-4 rounded-lg hover:shadow-lg cursor-pointer hover:scale-95 transition-transform duration-150"
                                >
                                  <div className="bg-[#91bd1f] w-9 mx-auto  rounded-full p-2">
                                    <PhoneIcon className="h-5 w-5 text-white" />
                                  </div>
                                  <h2 className="font-semibold mt-2">Voice </h2>
                                  <p className="mt-1 text-center">
                                    $ {vcv?.voice}
                                  </p>
                                </div>
                                <div
                                  onClick={() => {
                                    props.setOpenModal("form-elements");
                                    setServiceType("Video Call");
                                    setServicePrice(vcv?.video);
                                  }}
                                  className="flex-col bg-gray-100 p-4 rounded-lg hover:shadow-lg cursor-pointer hover:scale-95 transition-transform duration-150"
                                >
                                  <div className="bg-[#91bd1f] w-9 mx-auto  rounded-full p-2">
                                    <VideoCameraIcon className="w-5 h-5 text-white" />
                                  </div>{" "}
                                  <h2 className="font-semibold mt-2">Video </h2>
                                  <p className="mt-1 text-center">
                                    $ {vcv?.video}
                                  </p>
                                </div>
                              </>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Treebg h-full mt-[-1.4rem]">
            <div className="container mx-auto px-4">
              <p className="text-center  font-bold text-3xl">About Me</p>
              <div className="flex justify-center items-center">
                <div className="mt-8  bg-[#d2e8b4] max-w-screen-lg rounded-lg">
                  <p className="px-4 md:px-16 py-8 md:py-16 text-base md:text-[16px]">
                    {advisor[0]?.advisor?.biodata
                      ? advisor[0]?.advisor?.biodata
                      : "Meditation is a practice of focusing one's mind and attention in a particular way to achieve a state of mental calmness and relaxation. It is often used to reduce stress, improve concentration, and increase self-awareness."}
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center my-5">
                <img
                  className="w-64 md:w-80"
                  src={AboutUnder}
                  alt="aboutunderlogo"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="Treebg h-full">
          <LazyLoader />
        </div>
      )}
      <div className="mt-[-1.4rem]">
        <Footer />
      </div>
      <Modal
        show={props.openModal === "form-elements"}
        size="3xl"
        className=" overflow-y-auto "
        popup
        onClose={() => props.setOpenModal(undefined)}
      >
        <Modal.Body className="bg-[#daecc6] rounded-lg">
          <div className="flex justify-end items-center pt-2 -mr-3">
            <XMarkIcon
              color="red"
              strokeWidth={2}
              onClick={() => props.setOpenModal(undefined)}
              className="h-5 w-5 cursor-pointer	 hover:rounded-md hover:bg-blue-gray-100"
            />
          </div>
          <h3 className="text-xl text-center font-semibold text-black dark:text-white">
            Booking Slots
          </h3>
          <label
            for="date"
            className="block mt-5 mb-2 text-[16px] font-semibold  dark:text-white w-full text-center"
          >
            Click on calendar to select your date
          </label>
          <div className="flex items-center justify-center">
            <input
              id="date"
              datepicker
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 md:w-64 "
              value={date}
              // onChange={(e) => setDate(e.target.value)}
              onChange={(e) => {
                setDate(e.target.value);
                const selectedDate = new Date(e.target.value);
                const daysOfWeek = [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ];
                const dayName = daysOfWeek[selectedDate.getDay()];
                setSelectedDay(dayName);
              }}
            />
          </div>
          {date && (
            <div className="flex items-center justify-center flex-wrap gap-3 md:w-[40rem] my-5 md:mx-4 mx-0">
              {uniqueDays?.length > 0 ? (
                uniqueDays?.map((slot, index) => (
                  <button
                    key={index}
                    className={`px-4 py-2 border hover:scale-95 transition-transform duration-150 ${
                      selectedButton === slot?.id
                        ? "border-green-500 border-2 bg-green-200"
                        : "border-gray-400"
                    } ${
                      slot?.isBooked
                        ? "bg-gray-300 cursor-not-allowed hover:scale-100"
                        : null
                    } rounded-md hover:shadow-lg`}
                    onClick={() => handleButtonClick(slot?.id)}
                    disabled={slot?.isBooked}
                  >
                    {slot?.isBooked
                      ? `${slot.time} (Reserved)`
                      : `${slot.time} (30 Min.)`}
                  </button>
                ))
              ) : (
                <div className="w-full text-center">
                  No available slots found, please select another date.
                </div>
              )}
            </div>
          )}
          {selectedButton && stripecomp && (
            <>
              <Elements options={options} stripe={stripePromise}>
                <div className="md:mx-[1rem]">
                  <StripeTesting setOrderBtn={setOrderBtn} />
                </div>
              </Elements>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Adviser;
