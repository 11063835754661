import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <div className="Treequestionbg bg-[#dbeec5] h-full flex items-center justify-center ">
      <div className="loader">
        <div className="circle"></div>
      </div>
    </div>
  );
};

export default Loader;
