import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Newcircle } from "../assets";
import { Circle } from "rc-progress";
import axios from "axios";
import Header from "../Component/Header/Header";
import LazyLoader from "../Component/LazyLoader";
import AuthUser from "../Component/AuthUser";
import Footer from "../Component/Footer/Footer";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  BlobProvider,
  pdf,
} from "@react-pdf/renderer";

var totalItems = null;
const Question = () => {
  const { token, user } = AuthUser();
  // console.log(token);
  const { id } = useParams();
  const navigate = useNavigate();

  // get questions api
  const [loading, setLoading] = useState(false);
  const [userpdf, setUserPdf] = useState([]);
  const [progress, setProgress] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [surveyPDF, setSurveyPDF] = useState(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [answer, setAnswer] = useState([]);
  const [trace, setTrace] = useState(0);
  const [isRadioSelected, setIsRadioSelected] = useState(false);
  const getQuestions = (id) => {
    axios
      .get(`https://api.drmeditation.net/api/survey/show/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(true);
        // console.log(response.data.Question);
        setQuestions(response.data.Question[trace]);
        totalItems = response.data.Question.length;
        // response.data.Questions.map(
        //   (item) => //// console.log(item.question)
        // setPdfques(item.question)
        // );
        // //// console.log("------",resQues);
        // //// console.log(trace);
        // //// console.log(totalItems);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getQuestions(id);
    setLoading(false);
  }, [trace]);

  const handleRadioClick = (value, option, question) => {
    setSelectedQuestion(question);
    setSelectedOption(option);
    setSelectedValue((prevSelectedValues) => [
      ...prevSelectedValues,
      Number(value),
    ]);
    setIsRadioSelected(true);
    // //// console.log(selectedValue);
  };

  const onBack = () => {
    // //// console.log("back");
    setTrace(trace - 1);
    // //// console.log(trace);
    setProgress((prevProgress) => {
      const newProgress = prevProgress - 100 / totalItems;
      return newProgress < 0 ? 0 : newProgress; // Ensure progress doesn't go below 0%
    });
  };

  const onNext = () => {
    //// console.log(selectedValue);
    if (!isRadioSelected) {
      alert("Please select an option before moving to the next question.");
      return;
    }
    // Reset isRadioSelected to false for the next question
    setIsRadioSelected(false);
    const flattenedAnswers = selectedValue.flat(); // Flatten the array of arrays
    if (flattenedAnswers.length > 0) {
      setAnswer((prevAnswers) => [...prevAnswers, flattenedAnswers]);
    }
    setSelectedValue([]); // Reset selectedValue for next question
    // //// console.log("next");
    setTrace(trace + 1);
    // //// console.log(trace);
    setProgress((prevProgress) => {
      const newProgress = prevProgress + 100 / totalItems;
      return newProgress > 100 ? 100 : newProgress; // Ensure progress doesn't exceed 100%
    });
    setUserPdf((prevPdf) => [
      ...prevPdf,
      {
        question: selectedQuestion,
        option: selectedOption,
      },
    ]);
  };
  useEffect(() => {
    const generatePDF = async () => {
      try {
        const blob = await pdf(<UserHistory />).toBlob();
        // console.log("Generated Blob:", blob);
        setSurveyPDF(blob);
      } catch (error) {
        // console.error("Error generating PDF:", error);
      }
    };

    generatePDF();
  }, [userpdf]); // Assuming userpdf is the dependency for generating PDF

  const onSubmit = () => {
    //// console.log(pdf);
    const response = answer.flat();
    // const pdfData = new FormData();
    // pdfData.append("pdf", surveyPDF, `${user?.name}_survey_history.pdf`);
    const data = {
      user_id: user.id,
      subcategory_id: response,
      survey_pdf: surveyPDF,
    };
    // console.log(data);
    axios
      .post(`https://api.drmeditation.net/api/survey/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //// console.log(response.data);
        navigate("/result");
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const percent = Math.round(progress);

  // pdf styling and generation
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#daecc6",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      borderBottom: "2px solid black",
      paddingBottom: 2,
    },
    subtitle: {
      paddingVertical: 2,
      fontSize: 14,
      textAlign: "left",
    },
    question: {
      marginTop: 5,
      fontSize: 12,
      textAlign: "left",
    },
    option: {
      // marginTop: 1,
      fontSize: 10,
      textAlign: "left",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  const UserHistory = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>User Survey History</Text>

          <Text style={styles.subtitle}>Name : {user?.name}</Text>
          <Text style={styles.subtitle}>Email : {user?.email}</Text>
          <Text style={styles.title}></Text>
          <Text style={styles.subtitle}>
            Category : {questions?.category?.name}
          </Text>
          <Text style={styles.title}></Text>

          <br />
          {userpdf?.map((item, index) => (
            <>
              <br></br>
              <Text style={styles.question}>
                Question {index + 1} : {item.question}
              </Text>
              <Text style={styles.option}>Answer : {item.option}</Text>
              <hr></hr>
            </>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );

  return (
    <>
      <Header />
      <div className="Treequestionbg bg-[#dbeec5] h-full ">
        <div className="">
          <div className="container mx-auto sm:px-[3rem]  md:px-[15rem] px-[1.888rem] h-full ">
            <div className="">
              <div className="flex justify-center items-center relative ">
                <img
                  className="w-[8rem] absolute flex justify-center items-center ml-0.5 md:w-[9.5rem] sm:w-[8rem] md:mt-[0.2rem] "
                  src={Newcircle}
                  alt="circle"
                />
                <p className="text-center text-white absolute font-semibold md:mt-5 top-[5rem]">
                  {percent}%
                </p>
                <Circle
                  className="w-[8rem] sm:w-[9rem] md:w-[10rem] z-10"
                  percent={percent}
                  strokeWidth={10}
                  strokeColor="#6cc873"
                  strokeLinecap={0}
                  trailColor="#dbeec7"
                />
              </div>
            </div>
            {loading ? (
              <>
                <div className="mb-[1.888rem]">
                  {questions ? (
                    <h1 className="text-center mt-4 font-semibold text-xl ">
                      {questions.question}
                    </h1>
                  ) : (
                    <h1 className="text-center mt-4 font-semibold text-xl ">
                      {" "}
                      No Questions Available in this Category
                      <br></br>
                      Please Choose Another Category.
                      <br></br>
                      <br></br>
                      <button
                        className="px-[1rem] hover:bg-[#cfecc6] hover:border hover:border-white bg-white py-[0.3rem] rounded-2xl text-md font-semibold"
                        type="button"
                        onClick={() => navigate("/categories")}
                      >
                        Categories
                      </button>
                    </h1>
                  )}
                </div>
                <form>
                  {questions ? (
                    questions.options.map((option) => (
                      <>
                        <div
                          key={option.id}
                          className="flex items-center justify-center "
                        >
                          <div className="bg-gradient-to-r from-[#90be23] to-[#6ec871]  w-[40rem]  my-2 px-[2rem] py-[0.555rem] rounded-3xl flex items-center justify-left ">
                            <input
                              key={option.id}
                              id={`q-${option.id}`}
                              value={option.options_sub_cat.map(
                                (item) => item.subcategory_id
                              )}
                              onClick={() => {
                                option ? (
                                  option.options_sub_cat.map((item) => {
                                    handleRadioClick(
                                      item.subcategory_id,
                                      option.option,
                                      questions.question
                                    );
                                  })
                                ) : (
                                  <></>
                                );
                              }}
                              type="radio"
                              name="test"
                              className="w-4 h-4 text-green-400 focus:ring-green-500 cursor-pointer"
                            />
                            <label
                              htmlFor={`q-${option.id}`}
                              className="ml-2 text-sm font-medium text-white cursor-pointer"
                            >
                              {option.option}
                            </label>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                  <div className="flex items-center justify-center">
                    <div className="bg-gradient-to-r from-[#90be23] to-[#6ec871]  w-[40rem]  my-2 px-[2rem] py-[0.555rem] rounded-3xl flex items-center justify-left ">
                      <input
                        id="non"
                        onClick={() => {
                          handleRadioClick(
                            105,
                            "Non of the Above",
                            questions.question
                          );
                        }}
                        type="radio"
                        name="test"
                        className="w-4 h-4 text-green-400 focus:ring-green-500 cursor-pointer"
                      />
                      <label
                        htmlFor="non"
                        className="ml-2 text-sm font-medium text-white cursor-pointer"
                      >
                        Non of the Above
                      </label>
                    </div>
                  </div>
                </form>
                {questions ? (
                  <div className="flex justify-center items-center pt-[1.444rem] text-white">
                    {trace > 0 ? (
                      <button
                        className="px-[1rem] bg-gradient-to-r from-[#90be23] to-[#6ec871]   py-[0.3rem] rounded-2xl text-md font-semibold"
                        type="button"
                        onClick={onBack}
                      >
                        Back
                      </button>
                    ) : (
                      <div></div>
                    )}

                    <p className="px-[1.777rem]"> </p>
                    {!(trace === totalItems - 1) ? (
                      <button
                        className="px-[1rem]  py-[0.3rem] bg-gradient-to-r from-[#90be23] to-[#6ec871]   rounded-2xl text-md font-semibold"
                        type="button"
                        onClick={onNext}
                      >
                        Next
                      </button>
                    ) : (
                      <div>
                        <button
                          className="px-[1rem]  py-[0.3rem] bg-gradient-to-r from-[#90be23] to-[#6ec871]  rounded-2xl text-md font-semibold"
                          type="submit"
                          onClick={onSubmit}
                        >
                          Submit
                        </button>
                        <br></br>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <LazyLoader />
            )}
          </div>
        </div>
      </div>
      {/* <PDFViewer>
        <UserHistory />
      </PDFViewer>
      <PDFDownloadLink document={<UserHistory />} filename="FORM">
        {({ loading }) =>
          loading ? (
            <button>Loading Document...</button>
          ) : (
            <button>Download</button>
          )
        }
      </PDFDownloadLink>
      <BlobProvider document={UserHistory}>
        {({ blob, url, loading, error }) => {
          // Do whatever you need with blob here
          return <div>There's something going on on the fly</div>;
        }}
      </BlobProvider> */}
      <div className="mt-[-0.5rem]">
        <Footer />
      </div>
    </>
  );
};

export default Question;
