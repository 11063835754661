import { logo } from "../assets";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Component/Footer/Footer";
import Header from "../Component/Header/Header";
import React, { useState, useEffect } from "react";
import axios from "axios";
import LazyLoader from "../Component/LazyLoader";
import { Modal, Button } from "flowbite-react";
import AuthUser from "../Component/AuthUser";

const Result = () => {
  const { token, user } = AuthUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [loading, setLoading] = useState(false);
  const [subCat, setSubCat] = useState([]);
  const [id, setId] = useState(null);
  const [advisors, setAdvisors] = useState(false);

  const getResult = () => {
    axios
      .get(`https://api.drmeditation.net/api/myresult/show/${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.SubCategory.id == 105) {
          setAdvisors(true);
          return;
        }
        //// console.log(response.data);
        setSubCat(response.data.SubCategory);
        setId(response.data.SubCategory.id);
        setLoading(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getResult();
  }, []);

  return (
    <>
      <Header />
      <div>
        <Modal show={open} onClose={handleOpen}>
          <Modal.Header>
            All the previous Data will be deleted if you take a new survey
          </Modal.Header>

          <Modal.Footer>
            <Button
              className="bg-gradient-to-r from-[#90be23] to-[#6ec871]"
              onClick={() => navigate("/categories")}
            >
              Yes
            </Button>
            <Button
              className="bg-gradient-to-r from-[#90be23] to-[#6ec871]"
              onClick={handleOpen}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="Treeresultbg flex flex-col justify-center items-center ">
          <h2 className="text-black text-center text-xl md:text-xl font-semibold mt-[2rem] md:mt-[5rem] lg:mt-[6rem]">
            Your Result
          </h2>
          {/* text-[#D9F3B8] */}
          <h1 className="text-black text-center text-[1.222rem] md:text-[1.666rem] font-semibold">
            Dr. Meditation Survey
          </h1>
          <div className="container mx-auto sm:px-[3rem]  md:px-[15rem] px-[1.888rem] py-[1rem] ">
            {advisors ? (
              <>
                <div className="md:px-[5rem] px-[1rem] md:container md:mx-auto flex justify-center items-center ">
                  <div className="w-[30rem] mt-[2rem]  rounded-2xl">
                    <Link to={`/advisors`}>
                      <div className="Advisor h-80 sm:h-64 md:h-[16rem] lg:h-[18rem]">
                        <div className="flex fle-col justify-center items-center h-full">
                          <h className="text-4xl font-semibold text-black">
                            Advisors
                          </h>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className=" md:pb-[3rem] ">
                  <h1 className="text-black font-semibold tracking-wide text-[16px] py-3 md:text-xl text-center  ">
                    {" "}
                    Based On your Survey this Category Suits you !
                  </h1>
                  {loading ? (
                    <div className="flex flex-col justify-center  items-center">
                      <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl  ">
                        <img
                          className="object-cover p-2 rounded-t-lg h-[10rem] md:h-auto md:w-[14rem] md:px-[1.555rem]"
                          src={`https://api.drmeditation.net/SubCategoryThumbnail/${subCat.thumbnail}`}
                          alt=""
                        />
                        <div className="flex flex-col justify-between p-4 leading-normal">
                          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900">
                            {subCat.name}
                          </h5>
                          {/* <p className="mb-3 font-normal sm:line-clamp-2 md:line-clamp-3 line-clamp-2 text-gray-700 text-justify">
                            Mediation analysis quantifies the extent to which a
                            variable participates in the transmittance of change
                            from a cause to its effect. It is inherently a
                            causal notion, hence it cannot be defined in
                            statistical terms.
                          </p> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <LazyLoader />
                  )}
                </div>
              </>
            )}
          </div>

          <Button
            className="bg-gradient-to-r from-[#90be23] to-[#6ec871]"
            onClick={handleOpen}
          >
            Take Survey Again
          </Button>
        </div>

        {/* categories for result start */}
        <div className="Treeresultbg">
          {advisors ? (
            <></>
          ) : (
            <>
              <div className="">
                <h1 className="text-center md:pt-[3rem]  md:text-2xl font-bold text-2xl pt-[2rem]  ">
                  About your mind-body type
                </h1>
                <p className="text-center md:pt-[1rem] text-xl font-semibold">
                  {" "}
                  We are recommended you this{" "}
                </p>
              </div>
              <div className="px-[1rem] md:container md:mx-auto ">
                <div className="grid w-full md:grid-cols-4 gap-4 mt-[2rem] sm:grid-cols-3 sm:gap-2 md:pt-[3rem]">
                  <Link to={`/music/${id}?source=home`}>
                    <div className="Music h-80 rounded-xl sm:h-64 md:h-[16rem] lg:h-[18rem]">
                      <div className="flex fle-col justify-center items-center h-full">
                        <h className="text-4xl font-semibold mt-48 text-white hover:text-[#90be23] ">
                          Music
                        </h>
                      </div>
                    </div>
                  </Link>
                  <Link to={`/blog/${id}?source=home`}>
                    <div className="Blogs h-80 rounded-xl sm:h-64 md:h-[16rem] lg:h-[18rem]">
                      <div className="flex fle-col justify-center items-center h-full">
                        <h className="text-4xl font-semibold mt-48 text-white hover:text-[#90be23]">
                          Blogs
                        </h>
                      </div>
                    </div>
                  </Link>
                  <Link to={`/video/${id}?source=home`}>
                    <div className="Video h-80 rounded-xl sm:h-64 md:h-[16rem] lg:h-[18rem]">
                      <div className="flex fle-col justify-center items-center h-full">
                        <h className="text-4xl font-semibold mt-48 text-white hover:text-[#90be23]">
                          Video
                        </h>
                      </div>
                    </div>
                  </Link>
                  <Link to={`/podcast/${id}?source=home`}>
                    <div className="Podcasts rounded-xl  h-80 sm:h-64 md:h-[16rem] lg:h-[18rem]">
                      <div className="flex fle-col justify-center items-center h-full">
                        <h className="text-4xl font-semibold mt-48 text-white hover:text-[#90be23]">
                          Podcast
                        </h>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
        {/* categories for result end */}
        <div className="mobile-footer">
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Result;
