import React from "react";
import { logo } from "../assets";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
const AboutUs = () => {
  return (
    <div>
      <Header />
      <div className="Treebg ">
        <div className="flex flex-col justify-center items-center h-screen">
          <h1 className="text-4xl text-center font-semibold mt-10 pb-4">
            Welcome to Dr Meditation About Us
          </h1>
          <img className="w-32 pb-4" src={logo} alt="logo" />
          <p className="px-4 md:px-16 lg:w-2/3 text-start">
            <br />
            Dr. Meditation is an initiative established by a dedicated young
            professional who has devoted over three decades of his life to the
            exploration and experimentation of more than 30 meditation
            techniques. Throughout his journey, he has had the privilege of
            learning from over several esteemed spiritual teachers, acquiring
            knowledge in disciplines such as Bhakti yoga, Kriya yoga, tantra,
            vipassana, sound healing, and Chakra meditation. Now, he aspires to
            share these self-paced, goal-oriented meditation methods with all of
            you.
            <br />
            <br />
            It is important to recognize that while each technique holds value,
            ultimately, they must all be relinquished to attain the profound
            state of utter silence where one may realize their true self. Our
            carefully crafted techniques are designed to facilitate healing,
            energize the mind and body, and transform our inherent limitations
            into boundless possibilities.
            <br />
            <br />
            Our primary objective is to ensure that these scientific methods of
            meditation are accessible to all who are interested, regardless of
            their background. Whether you seek a simple sense of tranquility or
            yearn for the unparalleled ecstasy and elevated states of
            consciousness that surpass any drug or alcohol-induced experience,
            our practices are here to guide you on your journey. <br />
            <br />
          </p>
        </div>
      </div>
      <div className="categories-footer">
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
