import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../otp/firebase.config";
import AuthUser from "../../Component/AuthUser";

const SendMessage = () => {
  const [value, setValue] = useState("");
  const { user } = AuthUser();
  const now = new Date();

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      const { id, name, image, role_id } = user;
      await addDoc(collection(db, "messages"), {
        text: value,
        name: name,
        avatar: image,
        uid: id,
        role_id: role_id,
        createdAt: serverTimestamp(),
        time: `${now.getHours()}:${now.getMinutes()}`,
      });
    } catch (error) {
      //// console.log(error);
    }
    setValue("");
  };

  return (
    <div className="bg-[#c8e6a6] fixed bottom-0 w-full sm:px-10 py-10 shadow-lg">
      <form onSubmit={handleSendMessage} className="px-2 containerWrap flex">
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="input w-full focus:outline-none focus:border-none focus:ring-green-500 border-green-500 bg-gray-100 rounded-r-none"
          type="text"
          placeholder="Write Something..."
        />
        <button
          type="submit"
          className={`w-auto text-white rounded-r-lg px-5 text-sm ${
            value.trim() === "" ? "bg-green-300" : "bg-success"
          }`}
          disabled={value.trim() === ""}
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default SendMessage;
