import * as React from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import Header from "../../Component/Header/Header";
import AuthUser from "../../Component/AuthUser";

function randomID(len) {
  let result = "";
  if (result) return result;
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

export function getUrlParams(url = window.location.href) {
  let urlStr = url.split("?")[1];
  return new URLSearchParams(urlStr);
}

export default function Video() {
  const { user } = AuthUser();
  //// console.log(user);
  // roomid is staticly asigned for a advisor , it wont be changed
  const roomID = getUrlParams().get("roomID");
  // const roomID = getUrlParams().get("roomID") || randomID(5);
  let myMeeting = async (element) => {
    // generate Kit Token
    const appID = 867383197;
    const serverSecret = "c116c44c48119e94728094349d15ae5e";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      randomID(5),
      // "Enter Your Name"
      `${user.name}`
    );

    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      preJoinViewConfig: {
        title: "Enter Video Call with Advisor",
      },
      // sharedLinks: [
      //   {
      //     name: "Send this Link to User",
      //     url:
      //       window.location.protocol +
      //       "//" +
      //       window.location.host +
      //       window.location.pathname +
      //       "?roomID=" +
      //       roomID,
      //   },
      // ],
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
      turnOnCameraWhenJoining: true,
      showMyCameraToggleButton: true,
      showAudioVideoSettingsButton: true,
      showScreenSharingButton: false,
      showTextChat: true,
      showUserList: true,
      maxUsers: 2,
      layout: "Auto",
      showLayoutButton: false,
      showRoomDetailsButton: false,
    });
  };

  return (
    <>
      <Header />
      <div
        className="myCallContainer"
        ref={myMeeting}
        style={{ height: "100vh", display: "flex" }}
      >
        <div style={{ flex: "1" }}></div>
      </div>
    </>
  );
}
