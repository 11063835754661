import React from "react";
import "../../App.css";
import { logo } from "../../assets";
import { Link } from "react-router-dom";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div>
      <div className="Treefooterbg   h-full">
        <div className="container mx-auto -mt-2 pt-5 ">
          <div className="grid md:grid-cols-3 gap-5 mx-auto flex flex-col justify-center items-center border-t border-t-[0.04rem] border-t-gray-600 ">
            <div className="w-full  md:border-r-[0.04rem] md:border-r-black">
              <div className="flex flex-col justify-center items-center pt-[2rem] max-h-screen">
                <h2 className="md:border-b md:border-b-black pb-[0.5rem] text-xl font-bold">
                  Quick Links
                </h2>
                <ul className="flex flex-col justify-center items-center pt-[1rem] leading-8">
                  {/* <Link to={"/shop"}>
                    <li className="hover:underline">Shop</li>
                  </Link> */}
                  {/* <Link to={"/faq"}>
                    <li className="hover:underline">F.A.Q</li>
                  </Link> */}
                  <Link to={"/disclaimer"}>
                    <li className="hover:underline">Disclaimer</li>
                  </Link>
                  <Link to={"/privacy"}>
                    <li className="hover:underline">Privacy Policy</li>
                  </Link>
                  <Link to={"/terms"}>
                    <li className="hover:underline">Terms and Conditions</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="">
              <div className="flex flex-col justify-center items-center md:pt-[1rem] max-h-screen">
                <img className="w-[6rem]" src={logo} alt="logo" />
                <p className="text-center text-sm px-[3rem]  pt-2">
                  Meditation is a practice of focusing one's mind and attention
                  in a particular way to achieve a state of mental calmness and
                  relaxation.
                </p>
              </div>
            </div>
            <div className="w-full md:border-l md:border-l-[0.04rem] md:border-l-black">
              <div className="flex flex-col justify-center items-center  h-[11rem]">
                <h2 className="md:border-b md:border-b-black pb-[0.5rem] text-xl font-bold">
                  Contact Info
                </h2>
                <ul className="flex flex-col justify-center items-center pt-[1rem] leading-8">
                  {/* <li className="text-center">
                    6850 TPC Dr STE 108, McKinney, TX 75070
                  </li> */}
                  <li className="text-center flex items-center justify-center gap-x-2">
                    <EnvelopeIcon className="h-5 w-5 text-[#7ba314]" />
                    <a
                      href="mailto:support@drmeditation.net"
                      className="hover:underline cursor-pointer"
                    >
                      support@drmeditation.net
                    </a>
                  </li>

                  <li className="text-center flex items-center justify-center gap-x-2">
                    <PhoneIcon className="h-5 w-5 text-[#7ba314]" />
                    <a
                      href="tel:214-395-6757"
                      className="hover:underline cursor-pointer"
                    >
                      214-395-6757
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="border-t border-t-[0.04rem] border-t-gray-600 py-2 mt-5">
            <p className="text-center">
              Copyright © {year} Dr Meditation - All Rights Reserved.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
