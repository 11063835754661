import React, { useState } from "react";
import {
  EyeIcon,
  EyeSlashIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { arrows_exclamation } from "react-icons-kit/linea/arrows_exclamation";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";
import Icon from "react-icons-kit";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";

const NewPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const API = "https://api.drmeditation.net";
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  const handleChange = (value) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{6,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }

    setPass(value);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [pass, setPass] = useState("");
  const [type, setType] = useState("password");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !specialValidated ||
      !lengthValidated
    ) {
      // alert(
      //   "Please ensure that your Password meets all the validation criteria."
      // );
      toast.error(
        "Please ensure that your Password meets all the validation criteria."
      );
      setIsLoading(false);

      return;
    }
    const data = {
      token: token,
      password: pass,
      confirm_password: pass,
    };
    // console.log(data);
    axios
      .post(`${API}/api/resetPassword`, data)
      .then((res) => {
        // console.log(res.data);
        navigate("/login");
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error(error);
        toast.error(`Error ! Try again`);
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col flex-shrink justify-center items-center Treebg ">
      <div className="flex flex-col flex-shrink rounded-md w-[400px] min-h-screen justify-center items-center">
        <div className="flex flex-col flex-shrink  bg-[#92BE1F] rounded-md w-[400px] h-[60vh] justify-center items-center">
          <h3 className="text-white mb-4 text-2xl font-bold">New Password</h3>

          <form
            className="flex flex-col items-center space-x-6"
            onSubmit={handleSubmit}
          >
            {/* password */}
            <div>
              <div className="relative ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <LockClosedIcon className="w-5 h-5 text-gray-500" />
                </div>
                <input
                  required
                  type={type}
                  value={pass}
                  id="input-group-1"
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] pl-10 p-2.5  "
                  placeholder="New Password"
                  onChange={(e) => handleChange(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                  {type === "password" ? (
                    <span className="icon-span" onClick={() => setType("text")}>
                      <EyeIcon className="h-5 w-5" />
                    </span>
                  ) : (
                    <span
                      className="icon-span"
                      onClick={() => setType("password")}
                    >
                      <EyeSlashIcon className="h-5 w-5" />
                    </span>
                  )}
                </div>
              </div>
              {/* validation message checkboxes  */}
              {
                <main className="tracker-box">
                  <div
                    className={lowerValidated ? "validated" : "not-validated"}
                  >
                    {lowerValidated ? (
                      <span className="list-icon green">
                        <Icon icon={arrows_circle_check} />
                      </span>
                    ) : (
                      <span className="list-icon">
                        <Icon icon={arrows_exclamation} />
                      </span>
                    )}
                    At least one lowercase letter
                  </div>
                  <div
                    className={upperValidated ? "validated" : "not-validated"}
                  >
                    {upperValidated ? (
                      <span className="list-icon green">
                        <Icon icon={arrows_circle_check} />
                      </span>
                    ) : (
                      <span className="list-icon">
                        <Icon icon={arrows_exclamation} />
                      </span>
                    )}
                    At least one uppercase letter
                  </div>
                  <div
                    className={numberValidated ? "validated" : "not-validated"}
                  >
                    {numberValidated ? (
                      <span className="list-icon green">
                        <Icon icon={arrows_circle_check} />
                      </span>
                    ) : (
                      <span className="list-icon">
                        <Icon icon={arrows_exclamation} />
                      </span>
                    )}
                    At least one number
                  </div>
                  <div
                    className={specialValidated ? "validated" : "not-validated"}
                  >
                    {specialValidated ? (
                      <span className="list-icon green">
                        <Icon icon={arrows_circle_check} />
                      </span>
                    ) : (
                      <span className="list-icon">
                        <Icon icon={arrows_exclamation} />
                      </span>
                    )}
                    At least one special character
                  </div>
                  <div
                    className={lengthValidated ? "validated" : "not-validated"}
                  >
                    {lengthValidated ? (
                      <span className="list-icon green">
                        <Icon icon={arrows_circle_check} />
                      </span>
                    ) : (
                      <span className="list-icon">
                        <Icon icon={arrows_exclamation} />
                      </span>
                    )}
                    At least 6 characters
                  </div>
                </main>
              }
            </div>

            <div className="flex justify-center items-center ">
              <button className="bg-gradient-to-r from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-2 py-2 px-3 w-64 mt-[1.666rem] shadow-md ">
                {isLoading ? (
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  "Submit New Password"
                )}{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
