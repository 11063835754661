import React from "react";
import { Route, Routes, Link, Navigate } from "react-router-dom";
import Signup from "../pages/SignUp";
import Login from "../pages/Login";
import Forgot from "../pages/Forgot";
import OTP from "../pages/OTP";
import { logo } from "../assets";
import SignUpAdvisor from "../pages/regAdvisor/SignUpAdvisor";
import ForgotToken from "../pages/ForgotToken";
import NewPassword from "../pages/NewPassword";
import Chat from "../pages/Chat/Chat";
import VoiceCall from "../pages/VoiceCall/VoiceCall";
import VideoCall from "../pages/VideoCall/VideoCall";

const Guest = () => {
  return (
    <div>
      <div>
        {/* navbar start */}
        <div className="sticky bg-[#dbeec5] w-full  z-20 top-0 left-0">
          <nav className="backdrop-blur-md ">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
              <a href="#" className="flex items-center">
                <img src={logo} className="h-[5rem]  " alt="Flowbite Logo" />{" "}
                Dr. Meditation
              </a>{" "}
              <button
                data-collapse-toggle="navbar-solid-bg"
                type="button"
                className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-solid-bg"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div
                className="hidden w-full md:block md:w-auto"
                id="navbar-solid-bg"
              >
                <ul className="flex flex-col text-[16px] mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
                  <li>
                    <Link
                      to={"/login"}
                      className="block py-2 pl-3 pr-4  bg-green-700 rounded md:bg-transparent text-gray-900 md:p-0 md:hover:text-green-700 dark:bg-green-600 md:dark:bg-transparent"
                      aria-current="page"
                    >
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/signup"}
                      className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-700 md:p-0 dark:text-white md:dark:hover:text-green-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                    >
                      Sign Up
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to={"/forgot"}
                      className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-700 md:p-0 dark:text-white md:dark:hover:text-green-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                    >
                      Forgot Password
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
        {/* navbar end */}
      </div>
      <Routes>
        <Route path="*" element={<Navigate to={"/"} />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/regadvisor" element={<SignUpAdvisor />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/token" element={<ForgotToken />} />
        <Route path="/newpassword/:token" element={<NewPassword />} />
        <Route path="/otp/:id" element={<OTP />} />
        <Route path="/chat" element={<Chat />}></Route>
        <Route path="/voice" element={<VoiceCall />}></Route>
        <Route path="/video" element={<VideoCall />}></Route>
      </Routes>
    </div>
  );
};

export default Guest;
