import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { logo } from "../assets";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
const WelcomePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleSkip = () => {
    //// console.log(id);
    navigate(`/home/${id}`);
  };
  const handleQuestion = () => {
    //// console.log(id);
    navigate(`/question/${id}`);
  };
  return (
    <div>
      <Header />
      <div className="Treebg ">
        <div className="flex flex-col justify-center items-center h-screen">
          <h1 className="text-4xl text-center font-semibold mt-10 pb-4">
            Welcome to <br className="md:hidden block" /> Dr Meditation
          </h1>
          <img className="w-32 pb-4" src={logo} alt="logo" />
          <p className="px-4 md:px-16 lg:w-2/3 text-center">
            Meditation is a practice of focusing one's mind and attention in a
            particular way to achieve a state of mental calmness and relaxation.
            It is often used to reduce stress, improve concentration, and
            increase self-awareness. There are many different types of
            meditation, including mindfulness, loving-kindness, and
            transcendental meditation.
          </p>
          <div className="flex justify-center items-center pt-6 text-white">
            <button
              onClick={handleQuestion}
              className="px-4 bg-gradient-to-r from-[#90be23] to-[#6ec871]  py-2 rounded-2xl text-md font-semibold"
            >
              Conduct Survey
            </button>
            <p className="px-4"></p>
            <button
              onClick={handleSkip}
              className="px-4 bg-gradient-to-r from-[#90be23] to-[#6ec871] py-2 rounded-2xl text-md font-semibold"
            >
              Skip
            </button>
          </div>
        </div>
      </div>
      <div className="categories-footer">
        <Footer />
      </div>
    </div>
  );
};

export default WelcomePage;
