import React, { useState } from "react";
import {
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";
import Icon from "react-icons-kit";
import { arrows_exclamation } from "react-icons-kit/linea/arrows_exclamation";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";
import { Link } from "react-router-dom";
import { logo } from "../assets";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import AuthUser from "../Component/AuthUser";

const Signup = () => {
  // password validation
  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  const handleChange = (value) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{6,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }

    setPass(value);
  };
  const { http, setToken } = AuthUser();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [type, setType] = useState("password");
  const [number, setNumber] = useState("");
  const [err, setErr] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !specialValidated ||
      !lengthValidated
    ) {
      // alert(
      //   "Please ensure that your Password meets all the validation criteria."
      // );
      toast.error(
        "Please ensure that your Password meets all the validation criteria."
      );
      setIsLoading(false);

      return;
    }
    const data = {
      name: name,
      email: email,
      password: pass,
      phone: number,
    };
    //// console.log(data);
    http
      .post("/api/register", data)
      .then((res) => {
        //// console.log(res.data);
        setIsLoading(false);
        navigate(`/otp/${number}`);
      })
      .catch((error) => {
        // console.error(error);
        // setErr(error.response.data.errors[0]);
        toast.error(`${error.response.data.errors[0]}`);
        setIsLoading(false);
        // Handle error
      });
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col flex-shrink justify-center items-center overflow-y-auto Treebg w-full h-[100vh]">
      <Toaster toastOptions={{ duration: 2000 }} />

      <div className="grid md:grid-cols-2">
        <div>
          <div className="flex flex-col justify-center items-center w-full h-full md:mt-[-2rem] mt-[1.5rem]">
            <img src={logo} className="md:w-40 w-20" alt="logo" />
            <p className="md:text-[2rem] font-bold">
              Start your journey with us
            </p>
            <p className="md:text-[2rem] font-bold">Create Your Account</p>
          </div>
        </div>
        <div>
          <div className="flex flex-col flex-shrink  rounded-lg justify-center  md:h-[90vh] md:mt-0 mt-[3rem] items-center">
            <div className="flex flex-col bg-[#92BE1F] mb-4 rounded-2xl w-[380px] justify-center items-center mt-[0.444rem] md:ml-[6rem]">
              <h3 className="text-white text-2xl md:mb-4 md:text-2xl font-bold mt-[2rem]">
                SignUp
              </h3>

              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center "
              >
                {/* user image  */}
                {/* <div className="flex space-x-6 flex-shrink-0">
                  <img
                    className="h-16 w-16 object-cover rounded-full"
                    src="https://png.pngtree.com/png-vector/20191110/ourmid/pngtree-avatar-icon-profile-icon-member-login-vector-isolated-png-image_1978396.jpg"
                    alt="Current profile photo"
                  />
                  <input type="file" id="files" className="hidden" />
                  <label
                    className="bg-gradient-to-r from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-3 py-2 px-10  shadow-md"
                    for="files"
                  >
                    Upolad Profile
                  </label>
                </div> */}
                {/* full name */}
                <div>
                  <div className="relative mb-4">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <UserIcon className="w-5 h-5 text-gray-500" />
                    </div>
                    <input
                      value={name}
                      required
                      type="text"
                      id="input-group-1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] mt-[2rem] pl-10 p-2.5  "
                      placeholder="Full Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                {/* number */}

                <div className="relative mb-6">
                  <PhoneInput
                    country={"us"}
                    value={number}
                    onChange={setNumber}
                    className="mt-2"
                  />
                  {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <PhoneIcon className="w-5 h-5 text-gray-500" />
                    </div> */}

                  {/* <input
                      required
                      type="number"
                      id="input-group-1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block  pl-10 p-2.5  "
                      placeholder="Number"
                      onChange={(e) => setNumber(e.target.value)}
                    /> */}
                </div>

                {/* email */}
                <div>
                  <div className="relative mb-6">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <EnvelopeIcon className="w-5 h-5 text-gray-500" />
                    </div>
                    <input
                      required
                      value={email}
                      type="email"
                      id="input-group-1"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] pl-10 p-2.5  "
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                {/* password */}
                <div>
                  <div className="relative ">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <LockClosedIcon className="w-5 h-5 text-gray-500" />
                    </div>
                    <input
                      required
                      type={type}
                      value={pass}
                      id="input-group-1"
                      className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-3xl font-semibold  focus:ring-green-500 focus:border-green-500 block w-[18rem] pl-10 p-2.5  "
                      placeholder="Password"
                      onChange={(e) => handleChange(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 ">
                      {type === "password" ? (
                        <span
                          className="icon-span"
                          onClick={() => setType("text")}
                        >
                          <EyeIcon className="h-5 w-5" />
                        </span>
                      ) : (
                        <span
                          className="icon-span"
                          onClick={() => setType("password")}
                        >
                          <EyeSlashIcon className="h-5 w-5" />
                        </span>
                      )}
                    </div>
                  </div>
                  {/* validation message checkboxes  */}
                  {pass ? (
                    <main className="tracker-box">
                      <div
                        className={
                          lowerValidated ? "validated" : "not-validated"
                        }
                      >
                        {lowerValidated ? (
                          <span className="list-icon green">
                            <Icon icon={arrows_circle_check} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <Icon icon={arrows_exclamation} />
                          </span>
                        )}
                        At least one lowercase letter
                      </div>
                      <div
                        className={
                          upperValidated ? "validated" : "not-validated"
                        }
                      >
                        {upperValidated ? (
                          <span className="list-icon green">
                            <Icon icon={arrows_circle_check} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <Icon icon={arrows_exclamation} />
                          </span>
                        )}
                        At least one uppercase letter
                      </div>
                      <div
                        className={
                          numberValidated ? "validated" : "not-validated"
                        }
                      >
                        {numberValidated ? (
                          <span className="list-icon green">
                            <Icon icon={arrows_circle_check} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <Icon icon={arrows_exclamation} />
                          </span>
                        )}
                        At least one number
                      </div>
                      <div
                        className={
                          specialValidated ? "validated" : "not-validated"
                        }
                      >
                        {specialValidated ? (
                          <span className="list-icon green">
                            <Icon icon={arrows_circle_check} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <Icon icon={arrows_exclamation} />
                          </span>
                        )}
                        At least one special character
                      </div>
                      <div
                        className={
                          lengthValidated ? "validated" : "not-validated"
                        }
                      >
                        {lengthValidated ? (
                          <span className="list-icon green">
                            <Icon icon={arrows_circle_check} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <Icon icon={arrows_exclamation} />
                          </span>
                        )}
                        At least 6 characters
                      </div>
                    </main>
                  ) : (
                    <></>
                  )}
                  {err && (
                    <p className="text-red-500 bg-white rounded-md p-1">
                      {err}
                    </p>
                  )}
                </div>
                <div className="flex items-center">
                  <input
                    required
                    id="link-checkbox"
                    type="checkbox"
                    defaultValue=""
                    className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="link-checkbox"
                    className="ml-2 mt-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    I agree with the{" "}
                    <span className="hover:underline  text-green-800 hover:text-white">
                      terms & conditions
                    </span>
                    <br></br>
                    {" & "}
                    <span className="hover:underline  text-green-800 hover:text-white">
                      Privacy Policy
                    </span>
                  </label>
                </div>
                <div className="flex justify-center items-center ">
                  <button
                    type="submit"
                    className="bg-gradient-to-r from-gray-800 to-[#6ec771] rounded-full text-white font-normal m-2 py-2 px-3 w-64  shadow-md "
                  >
                    {isLoading ? (
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      "Register"
                    )}{" "}
                  </button>
                </div>
                <label
                  htmlFor="link-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Already Have an Account? {""}
                  <Link
                    className="hover:underline text-green-800 hover:text-white"
                    to="/login"
                  >
                    Login
                  </Link>
                  <br />
                  <br />
                </label>
                <div></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
