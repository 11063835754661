import React, { useState, useEffect } from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import axios from "axios";
import { Dialog, DialogBody, Typography } from "@material-tailwind/react";
import LazyLoader from "../../Component/LazyLoader";
import { XMarkIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import AuthUser from "../../Component/AuthUser";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Surveybg } from "../../assets";
import { Modal } from "flowbite-react";

// import { HeartIcon, ShareIcon } from "@heroicons/react/24/solid";

const Blogs = () => {
  const navigate = useNavigate();
  const { token, user } = AuthUser();
  const [subuser, setSubUser] = useState(false);
  React.useEffect(() => {
    axios
      .get(
        `https://api.drmeditation.net/api/subscription/mySubscription/${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        res.data.SubscribeUser.map((u) => {
          if (Number(u.user_id) === Number(user.id)) {
            setSubUser(true);
          }
        });
      });
  }, []);
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  let apiToRender;
  if (source === "home") {
    apiToRender = `https://api.drmeditation.net/api/home/${id}`;
    //// console.log("from home", apiToRender);
  } else if (source === "result") {
    apiToRender = `https://api.drmeditation.net/api/myresult/show/${user.id}`;
    //// console.log("from result", apiToRender);
  } else {
    // handle invalid or missing source parameter
    //// console.log("nothing");
  }

  // get api
  const getBlogs = () => {
    const userId = user.id;
    axios
      .get(apiToRender, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.Blog);
        setBlogs(response.data.Blog);
        setLoading(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getBlogs();
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (blog) => {
    setSelectedBlog(blog);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedBlog(null);
    setOpen(false);
  };
  return (
    <div>
      <Header />
      <div className="MainBlogsbg h-[30rem]">
        <div className="container mx-auto">
          <div className="flex md:justify-end justify-center items-center h-[30rem] ">
            {/* <h1 className="text-white text-[6rem] font-bold mr-0 md:mr-28">
              Blogs
            </h1> */}
          </div>
        </div>
      </div>
      <div className=" mx-auto md:px-[5rem] Treeblogsbg">
        <br />
        <br />
        <h2 className=" pl-[2rem] pb-[2rem] text-[2rem] font-semibold text-center">
          Blogs
        </h2>
        <div className="flex flex-wrap items-center justify-center gap-2">
          {/* <Link> */}
          {loading ? (
            blogs.length ? (
              blogs.map((blog) => (
                <>
                  {subuser ? (
                    <>
                      <div
                        onClick={() => handleOpen(blog)}
                        className=" py-[1rem]  cursor-pointer hover:scale-95 transition-transform duration-150"
                      >
                        <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50 ">
                          <a>
                            <img
                              className="rounded-t-lg md:w-[15rem] w-[13rem] h-[10rem] "
                              src={`https://api.drmeditation.net/BlogThumbnail/${blog.thumbnail}`}
                              // src={Surveybg}
                              alt="logo"
                            />
                          </a>
                          <div className=" p-2">
                            <h5 className="mb-1 text-[16px] text-center font-medium tracking-tight line-clamp-2 text-gray-900 ">
                              {blog.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : blog.subscription === "free" ? (
                    <>
                      <div
                        onClick={() => handleOpen(blog)}
                        className=" py-[1rem]  cursor-pointer hover:scale-95 transition-transform duration-150"
                      >
                        <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50 ">
                          <a>
                            <img
                              className="rounded-t-lg md:w-[20rem] w-[15rem] md:h-[10rem]"
                              src={`https://api.drmeditation.net/BlogThumbnail/${blog.thumbnail}`}
                              // src={Surveybg}
                              alt="logo"
                            />
                          </a>
                          <div className="p-2">
                            <h5 className="mb-1 text-[16px] text-center font-medium tracking-tight line-clamp-2 text-gray-900 ">
                              {blog.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className=" py-[1rem]  cursor-pointer relative hover:scale-95 transition-transform duration-150"
                        onClick={() => navigate("/subscriptions")}
                      >
                        <div className="absolute z-10 text-[#7ba314]  p-4 rounded-full bottom-4 right-0 ">
                          <LockClosedIcon className="h-5 w-5 " />
                        </div>
                        <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50 ">
                          <a>
                            <img
                              className="rounded-t-lg md:w-[20rem] w-[15rem] md:h-[10rem]"
                              src={`https://api.drmeditation.net/BlogThumbnail/${blog.thumbnail}`}
                              // src={Surveybg}
                              alt="logo"
                            />
                          </a>
                          <div className="p-2">
                            <h5 className="mb-1 text-[16px] text-center font-medium tracking-tight text-gray-900 line-clamp-2 ">
                              {blog.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))
            ) : (
              <p className="text-center md:pt-[1rem] text-xl font-semibold">
                There are no blogs in this Category.
              </p>
            )
          ) : (
            <LazyLoader />
          )}

          {/* </Link> */}
          {selectedBlog && (
            <Dialog
              className="bg-[#edf5db] h-screen overflow-y-scroll"
              open={open}
              handler={handleOpen}
              size="xxl"
            >
              <DialogBody className="">
                <div className="flex justify-end">
                  <XMarkIcon
                    color="red"
                    strokeWidth={2}
                    onClick={handleClose}
                    className="m-1 h-5 w-5 cursor-pointer	 hover:rounded-md hover:bg-blue-gray-100"
                  />
                </div>
                <div className="flex flex-col items-start justify-start md:mx-8">
                  {" "}
                  <Typography variant="h2" className="text-black">
                    {" "}
                    {selectedBlog.title}
                  </Typography>
                  <br></br>
                  <Typography
                    variant="paragraph"
                    className="text-black text-left"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedBlog.content }}
                    />
                  </Typography>
                  <br></br>
                  <Typography variant="h5" className="text-black">
                    By : {selectedBlog.author}
                  </Typography>
                  <br></br>
                </div>
              </DialogBody>
            </Dialog>
          )}
        </div>
      </div>
      <div className="mt-[-0.5rem]">
        <Footer />
      </div>
    </div>
  );
};

export default Blogs;
