import React, { useState, useEffect } from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import axios from "axios";
import {
  PlayCircleIcon,
  XMarkIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import LazyLoader from "../../Component/LazyLoader";
import { Dialog, DialogBody, Typography } from "@material-tailwind/react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

// video player
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import AuthUser from "../../Component/AuthUser";
import { Surveybg } from "../../assets";

const Podcast = () => {
  const navigate = useNavigate();
  const { token, user } = AuthUser();
  const [subuser, setSubUser] = useState(false);
  React.useEffect(() => {
    axios
      .get(
        `https://api.drmeditation.net/api/subscription/mySubscription/${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        res.data.SubscribeUser.map((u) => {
          if (Number(u.user_id) === Number(user.id)) {
            setSubUser(true);
          }
        });
      });
  }, []);
  const [loading, setLoading] = useState(false);
  const [vid, setVid] = useState([]);
  const [selectedVid, setSelectedVid] = useState(null);

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  let apiToRender;
  if (source === "home") {
    apiToRender = `https://api.drmeditation.net/api/home/${id}`;
    //// console.log("from home", apiToRender);
  } else if (source === "result") {
    apiToRender = `https://api.drmeditation.net/api/myresult/show/${user.id}`;
    //// console.log("from result", apiToRender);
  } else {
    // handle invalid or missing source parameter
    //// console.log("nothing");
  }

  // get api
  const getVideo = () => {
    const userId = user.id;
    axios
      .get(apiToRender, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //// console.log(response.data.Video);
        setVid(response.data.Video);
        setLoading(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getVideo();
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = (video) => {
    setSelectedVid(video);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedVid(null);
    setOpen(false);
  };
  return (
    <div>
      <Header />
      <div className="Videobg h-[30rem]">
        <div className="container mx-auto">
          <div className="flex md:justify-start justify-center items-center h-[30rem] ">
            {/* <h1 className="text-white text-center text-[6rem] font-bold">
              Videos
            </h1> */}
          </div>
        </div>
      </div>
      <div className=" bg-[#dbeec5] pt-[3rem] pb-[3rem]">
        {" "}
        <h2 className=" pb-[2rem] text-[2rem] font-semibold text-center">
          Videos
        </h2>
      </div>
      <div className="bg-[#dbeec5] Treeblogsbg">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center justify-center gap-2">
            {loading ? (
              vid.length ? (
                vid.map((video) => (
                  <>
                    {subuser ? (
                      <>
                        <div
                          onClick={() => handleOpen(video)}
                          className=" py-[1rem]  cursor-pointer hover:scale-95 transition-transform duration-150"
                        >
                          <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50 ">
                            <a>
                              <img
                                className="rounded-t-lg md:w-[15rem] w-[13rem] h-[10rem] "
                                src={`https://api.drmeditation.net/VideoThumbnail/${video.thumbnail}`}
                                // src={Surveybg}
                                alt="logo"
                              />
                            </a>
                            <div className=" p-2">
                              <a>
                                <h5 className="mb-2 text-[16px] text-center truncate font-medium tracking-tight text-gray-900 ">
                                  {video.name}
                                </h5>
                              </a>
                              {/* <p className="mb-3 text-sm text-center  font-normal text-gray-700  line-clamp-2">
                                {video.description}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : video.subscription === "free" ? (
                      <>
                        <div
                          onClick={() => handleOpen(video)}
                          className=" py-[1rem]  cursor-pointer hover:scale-95 transition-transform duration-150"
                        >
                          <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50 ">
                            <a>
                              <img
                                className="rounded-t-lg md:w-[15rem] w-[13rem] h-[10rem] "
                                src={`https://api.drmeditation.net/VideoThumbnail/${video.thumbnail}`}
                                // src={Surveybg}
                                alt="logo"
                              />
                            </a>
                            <div className=" p-2">
                              <a>
                                <h5 className="mb-2 text-[16px] text-center truncate font-medium tracking-tight text-gray-900 ">
                                  {video.name}
                                </h5>
                              </a>
                              {/* <p className="mb-3 text-sm text-center  font-normal text-gray-700  line-clamp-2">
                                {video.description}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className=" relative  py-[1rem]  cursor-pointer hover:scale-95 transition-transform duration-150"
                          onClick={() => navigate("/subscriptions")}
                        >
                          <div className="absolute z-10 text-[#7ba314]  p-2 rounded-full bottom-4 right-0 ">
                            <LockClosedIcon className="h-5 w-5 " />
                          </div>
                          <div className="shadow-lg md:w-[15rem] w-[13rem] md:h-[14rem] h-[13rem] shadow-gray-500/50 max-w-sm bg-white border border-gray-200 rounded-lg hover:shadow-lg hover:shadow-gray-700/50 ">
                            <a>
                              <img
                                className="rounded-t-lg md:w-[15rem] w-[13rem] h-[10rem] "
                                src={`https://api.drmeditation.net/VideoThumbnail/${video.thumbnail}`}
                                // src={Surveybg}
                                alt="logo"
                              />
                            </a>
                            <div className=" p-2">
                              <a>
                                <h5 className="mb-2 text-[16px] text-center truncate font-medium tracking-tight text-gray-900 ">
                                  {video.name}
                                </h5>
                              </a>
                              {/* <p className="mb-3 text-sm text-center  font-normal text-gray-700  line-clamp-2">
                                {video.description}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))
              ) : (
                <p className=" md:pt-[1rem] text-xl text-center font-semibold">
                  There are no Videos in this Category.
                </p>
              )
            ) : (
              <LazyLoader />
            )}
            {selectedVid && (
              <Dialog
                className="overflow-y-auto bg-transparent h-fit  rounded-lg "
                size="xl"
                open={open}
                handler={handleClose}
              >
                <DialogBody divider className="p-0 px-[1rem] pt-[1rem] ">
                  <div className="relative">
                    <div className="absolute -top-5 -right-3 md:right-1/4">
                      <XMarkIcon
                        color="white"
                        strokeWidth={5}
                        onClick={handleClose}
                        className="m-1 h-8 w-8 cursor-pointer	border-white border-2 rounded-full "
                      />
                    </div>
                  </div>
                  {/* <Typography variant="h6" className="text-black">
                    {selectedVid.name}
                  </Typography> */}

                  {/* <Typography variant="h6" className="text-black">
                    {selectedVid.description}
                  </Typography> */}
                  <br></br>

                  <div className=" flex items-center justify-center pb-3 ">
                    <Video
                      className="absolute top-0 left-0 md:w-[30rem] md:h-[25rem] h-fit"
                      controls={[
                        "PlayPause",
                        "Seek",
                        "Time",
                        "Volume",
                        "Fullscreen",
                      ]}
                      // poster={`https://api.drmeditation.net/VideoThumbnail/${selectedVid.thumbnail}`}
                    >
                      <source
                        src={`https://api.drmeditation.net/Video/${selectedVid.video}`}
                        type="video/webm"
                      />
                    </Video>
                  </div>
                </DialogBody>
              </Dialog>
            )}
          </div>
        </div>
      </div>
      <div className="mt-[-0.5rem]">
        <Footer />
      </div>
    </div>
  );
};

export default Podcast;
