import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";

const Waveform = ({ audio }) => {
  const containerRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => true,
  });
  const [isPlaying, toggleIsPlaying] = useState(false);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      barWidth: 2,
      barHeight: 2,
      barRadius: 2,
      cursorWidth: 0,
      barWidth: 0,
      waveColor: "#fff",
      progressColor: "#6ed370",
    });
    waveSurfer.load(audio);
    waveSurfer.on("ready", () => {
      waveSurferRef.current = waveSurfer;
      waveSurfer.play(); // Play the audio when it's ready
      toggleIsPlaying(true); // Update the state
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  return (
    <>
      <div className="w-full " ref={containerRef} />
      <div className="flex mt-5 justify-center items-center">
        <button
          className="w-auto   border-none bg-transparent"
          onClick={() => {
            waveSurferRef.current.playPause();
            toggleIsPlaying(waveSurferRef.current.isPlaying());
          }}
          type="button"
        >
          {isPlaying ? (
            <FaPauseCircle size="3em" />
          ) : (
            <FaPlayCircle size="3em" />
          )}
        </button>
      </div>
    </>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};

export default Waveform;
