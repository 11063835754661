import React, { useState, useEffect } from "react";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import axios from "axios";
import LazyLoader from "../../Component/LazyLoader";
import AuthUser from "../../Component/AuthUser";
import { useNavigate } from "react-router-dom";
import { AdviserUser } from "../../assets";

const Advisors = () => {
  const navigate = useNavigate();
  const { token, user } = AuthUser();
  const [loading, setLoading] = useState(false);
  const [advisors, setAdvisors] = useState([]);
  // get api
  const getAdvisors = () => {
    axios
      .get(`https://api.drmeditation.net/api/advisors/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setAdvisors(response.data.all_advisor);
        setLoading(true);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  useEffect(() => {
    getAdvisors();
  }, []);

  const handleClick = (id) => {
    navigate(`/advisor/${id}`);
  };

  return (
    <div>
      <Header />

      <div className=" mx-auto md:px-[5rem] h-screen Treeblogsbg">
        <h1 className="text-center md:pt-[3rem] md:text-2xl underline font-bold ">
          All Advisors
        </h1>
        <div className="p-2 gap-4  flex flex-wrap justify-center items-center md:pt-[3rem]">
          {loading ? (
            advisors.map((advisor) => (
              <div
                className="bg-[#c1d6a5] hover:bg-[#b4c89c] cursor-pointer h-80 w-80 rounded-2xl drop-shadow-xl hover:scale-95 transition-transform duration-150"
                onClick={() => handleClick(advisor?.id)}
              >
                <div className="flex justify-end p-5">
                  {Number(advisor?.is_online) === 1 ? (
                    <div className="w-16 bg-green-400 text-white rounded-2xl flex justify-evenly items-center">
                      online
                    </div>
                  ) : (
                    <div className="w-16 bg-red-400 text-white rounded-2xl flex justify-evenly items-center">
                      offline
                    </div>
                  )}
                </div>
                <div className="h-48 w-48 rounded-lg bg-white mx-auto">
                  <img
                    className="h-48 w-48 rounded-lg"
                    src={
                      advisor?.image
                        ? `https://api.drmeditation.net/Profile/${advisor?.image}`
                        : AdviserUser
                    }
                    alt="advisor img"
                  />
                </div>
                <div className="text-black font-bold text-2xl mt-4 mx-auto w-full text-center">
                  {advisor?.name}
                </div>
              </div>
            ))
          ) : (
            <LazyLoader />
          )}
        </div>
      </div>
      <div className="mt-[-0.5rem]">
        <Footer />
      </div>
    </div>
  );
};

export default Advisors;
