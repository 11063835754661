import ChatBox from "./ChatBox";
import SendMessage from "./SendMessage";
import Header from "../../Component/Header/Header";

const Chat = () => {
  return (
    <div className="">
      <Header />
      <ChatBox />
      <SendMessage />
    </div>
  );
};

export default Chat;
